<template>
    <div>
        <b-form-group>
            <label class="typo__label">{{ $t('forms.downtimetype') }}</label>
            <multiselect v-model="resetCheckTimeOptionSelected"
                         track-by="value"
                         :close-on-select="true"
                         :show-labels="false"
                         label="text"
                         placeholder="Select one"
                         :options="resetCheckTimeOptions"
                         :searchable="false"
                         :allow-empty="false">
            </multiselect>
        </b-form-group>
        <br>
        <b-button variant="success" class="w-100" v-on:click="sendResetCheckTime">{{
                $t('forms.send-command')
            }}
        </b-button>
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
    name: "HostResetCheckTime",
    components: {Multiselect},
    data(){
        return {
            resetCheckTimeOptionSelected: {
                value: 'SCHEDULE_FORCED_HOST_CHECK',
                text: this.$t('hosts.individual')
            },
            resetCheckTimeOptions: [
                {
                    value: 'SCHEDULE_FORCED_HOST_CHECK',
                    text: this.$t('hosts.individual')
                },
                {
                    value: 'SCHEDULE_FORCED_HOST_SVC_CHECKS',
                    text: this.$t('hosts.including-services')
                }
            ],
        }
    },
    props: ['hostids'],
    methods: {
        sendResetCheckTime: function(){
            if(this.hostids.length === 0){
                this.$notify({
                    type: 'error',
                    title: 'no hosts selected',
                })
                return
            }
            let externalCommand = []
            for(let host in this.hostids){
                externalCommand.push({
                    command: 'SCHEDULE_FORCED_HOST_CHECK',
                    hostUuid: this.hostids[host].hostUuid
                })
                if(this.resetCheckTimeOptionSelected.value === 'SCHEDULE_FORCED_HOST_SVC_CHECKS'){
                    externalCommand.push({
                        command: 'SCHEDULE_FORCED_HOST_SVC_CHECKS',
                        hostUuid: this.hostids[host].hostUuid
                    })
                }

            }

           // externalCommand = JSON.stringify(Object.assign({}, externalCommand));

          /*  axios.post('/hosts/external_commands.json', null, {
                params: {
                    externalCommand: externalCommand,
                }
            }) */
            axios.post('/hosts/external_commands.json', externalCommand)
                .then(response => {
                        if(response.data.response.code && response.data.response.code === 403){
                            this.$notify({
                                type: 'error',
                                title: 'insufficient rights',
                                text: 'Action forbidden for this user',
                                duration: 3000
                            });
                        }else{
                            this.$notify({
                                type: 'success',
                                title: '',
                                text: this.$t('Command successfully sent'),
                                duration: 7000
                            });
                        }
                        this.$emit('refresh')
                })
                .catch(error => {
                    this.$notify({
                        type: 'error',
                        title: this.$t('error'),
                    });
                });
        },
    },
    mounted(){
    },
}
</script>

<style scoped>

</style>
