<template>
    <div class="w-100 min-vh-100 login-screen" ref="loader" v-bind:style="{ 'background-image': 'url(' + bg_image + ')' }">
        <div class="container">
            <div class="row">
                <div class="col-md-6 mx-auto login-form-1 min-vh-100 login-form-bg">
                    <div class="col-12 text-center" style="padding-top: 80px;">
                        <img class="img-fluid" :src="logo" style="max-height: 230px;"/>
                    </div>
                    <h3>Login</h3>
                    <form @submit="login">
                        <div class="form-group">
                            <input required id="server" v-model="base_url" type="text" class="form-control"
                                   :placeholder="$t('login-server')"
                                   :readonly="predefined_server_url"
                                   value="" ref="server"/>
                        </div>
                        <div class="form-group">
                            <input required id="key" v-model="api_key" type="password" class="form-control"
                                   :placeholder="$t('login-key')" value=""
                                   @keyup.enter="login"/>
                        </div>
                        <div class="d-flex w-100 justify-content-between">
                            <b-form-checkbox
                                id="rememberMe"
                                v-model="remember_me">
                                Remember me
                            </b-form-checkbox>
                            <button class="btn btn-primary" type="button" value="Login" @click="login">
                            <span>
                                  <i class="fa fa-spinner fa-spin" v-show="loading"></i>
                            </span>
                                Login
                            </button>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "Login",
    data(){
        return {
            creds: '',
            base_url: '',
            api_key: '',
            remember_me: true,
            logo: '',
            bg_image: '',
            loading: false,
            predefined_server_url: false
        };
    },
    created(){


    },
    mounted(){
        this.focusInput();
        axios.post('/users/getSystemConfig.json')
            .then(response => {
                this.logo = response.data.loginLogo;
                this.bg_image = response.data.loginBg;
                if(response.data.serverUrl.length > 0){
                    // server-url is defined in interface.yml
                    this.predefined_server_url = true;
                    this.base_url = response.data.serverUrl;
                }
            })
            .catch(error => {
                console.log('Could not fetch logo');
            });
    },
    methods: {
        focusInput(){
            this.$refs.server.focus()
        },
        login(){
            this.loading = true
            let loader = this.$loading.show();
            axios.post('/users/login', {
                url: this.base_url,
                key: this.api_key,
                remember_me: this.remember_me
            })
                .then(response => {
                    loader.hide()
                    if(response.status === 200 && response.data.loggedIn === true){
                        localStorage.setItem('status', response.data.loggedIn);
                        this.$store.commit('setState', response.data.loggedIn);
                        localStorage.setItem('email', response.data.email);
                        this.$store.commit('setEmail', response.data.email);
                        localStorage.setItem('user', response.data.user);
                        this.$store.commit('setUser', response.data.user);
                        localStorage.setItem('user_timezone', response.data.user_timezone);
                        this.$store.commit('setUserTimezone', response.data.user_timezone);

                        //set logout-button in navbar
                        this.$root.$emit('login-event');
                        this.$router.push({name: 'dashboardsIndex'});
                    }else if(response.status === 500){
                        this.$notify({
                            type: 'error',
                            title: 'Server error',
                        })
                    }else{
                        if(response.data.type === 'error'){
                            this.$notify({
                                type: 'error',
                                title: response.data.message,
                            })
                        }
                    }
                    this.loading = false
                })
                .catch(error => {
                    loader.hide()
                    this.$notify({
                        type: 'error',
                        title: 'login not possible',
                        text: error,
                    })
                    this.running = false
                });
        },
    },
    watch: {}
}
</script>

<style scoped>

</style>
