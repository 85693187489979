<template>
    <!-- Downtime currently running -->
    <div class="h-100 status-line bg-downtime-running shadow-downtime-running"
         v-if="downtime.isRunning">
    </div>

    <!-- Downtime is expired -->
    <div class="h-100 status-line bg-downtime-expired shadow-downtime-expired"
         v-else-if="downtime.isExpired">
    </div>

    <!-- Downtime was cancelled -->
    <div class="h-100 status-line bg-downtime-cancelled shadow-downtime-cancelled"
         v-else-if="downtime.wasCancelled">
    </div>

    <!-- Downtime not started yet -->
    <div class="h-100 status-line bg-downtime-upcoming shadow-downtime-upcoming"
         v-else-if="!downtime.wasCancelled && !downtime.wasStarted && !downtime.isExpired">
    </div>
</template>

<script>
export default {
    name: "DowntimeState",
    data(){
        return {}
    },
    props: {
        downtime: Object
    },
    computed: {},
    mounted(){
        //console.log(this.downtime);
    },
    methods: {}
}
</script>

<style scoped>

</style>
