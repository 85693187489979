import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        status: localStorage.getItem('status') || false,
        key: localStorage.getItem('key') || '',
        server: localStorage.getItem('server') || '',
        email: localStorage.getItem('email') || '',
        user: localStorage.getItem('user') || '',
        version: localStorage.getItem('version') || '',
        userTimezone: localStorage.getItem('user_timezone') || '',
        theme: localStorage.getItem('theme') || ''
    },
    actions: {},
    mutations: {
        setState(state, value){
            state.status = value;
        },
        setKey(state, value){
            state.key = value;
        },
        setServer(state, value){
            state.server = value;
        },
        setEmail(state, value){
            state.email = value;
        },
        setUser(state, value){
            state.user = value;
        },
        setVersion(state, value){
            state.version = value;
        },
        setUserTimezone(state, value){
            state.userTimezone = value;
        },
        setTheme(state, value){
            state.theme = value;
        }
    },
    getters: {},
    modules: {},
});

export default store;
