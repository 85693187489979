import Vue from 'vue';
import VueRouter from 'vue-router';
import DashboardsIndex from './components/Dashboard.vue';
import ServicesIndex from './components/services/ServiceIndex.vue';
import ServicesDetails from './components/services/ServicesDetails.vue';
import HostsIndex from './components/hosts/HostsIndex.vue';
import HostsDetails from './components/hosts/HostsDetails.vue';
import DowntimesHosts from './components/downtimes/DowntimesHosts.vue';
import DowntimesHostsAdd from "./components/downtimes/DowntimesHostsAdd";
import Login from './components/Login.vue';
import Root from './components/Root.vue';
import App from './views/App.vue';
import DowntimesServices from "./components/downtimes/DowntimesServices";
import DowntimesServicesAdd from "./components/downtimes/DowntimesServicesAdd";

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'hash',
    routes: [
        {
            path: '',
            name: 'root',
            component: Root
        },
        {
            path: '/',
            name: 'app',
            component: App
        },
        {
            path: '/dashboards/index',
            name: 'dashboardsIndex',
            component: DashboardsIndex
        },
        {
            path: '/services/index',
            name: 'servicesIndex',
            props: true,
            component: ServicesIndex
        },
        {
            path: '/services/details/:id',
            name: 'servicesDetails',
            component: ServicesDetails
        },
        {
            path: '/hosts/index',
            name: 'hostsIndex',
            props: true,
            component: HostsIndex
        },
        {
            path: '/hosts/details/:id',
            name: 'hostsDetails',
            component: HostsDetails
        },
        {
            path: '/downtimes/hosts',
            name: 'downtimesHost',
            props: {
                triggerRefresh: false
            },
            component: DowntimesHosts
        },
        {
            path: '/downtimes/hosts_add',
            name: 'downtimesHostAdd',
            component: DowntimesHostsAdd
        },
        {
            path: '/downtimes/services',
            name: 'downtimesService',
            props: {
                triggerRefresh: false
            },
            component: DowntimesServices
        },
        {
            path: '/downtimes/services_add',
            name: 'downtimesServiceAdd',
            component: DowntimesServicesAdd
        },
        {
            path: '',//'/users/login',
            name: 'login',
            component: Login
        }
    ],
});
export default router;
