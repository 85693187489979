<template>
    <div>
        <b-button class="w-100 mb-1"
                  @click="disableNotifications" variant="outline-secondary">
            <i class="fa-solid fa-envelope"></i>
            {{ $t('action-items.notifications-disable') }}
        </b-button>
        <b-button class="w-100 mb-1"
                  @click="enableNotifications" variant="outline-secondary">
            <i class="fa-solid fa-envelope"></i>
            {{ $t('action-items.notifications-enable') }}
        </b-button>
    </div>
</template>

<script>
export default {
    name: "Notifications",
    props: [
        'uuids'
    ],
    methods: {
        enableNotifications(){
            let externalCommands = [];
            if(this.uuids.length > 0){
                for(var i = 0; i < this.uuids.length; i++){
                    externalCommands.push({
                        serviceUuid: this.uuids[i].serviceUuid,
                        hostUuid: this.uuids[i].hostUuid,
                        command: 'ENABLE_SVC_NOTIFICATIONS',

                    });
                }
                this.$emit('masscommand', externalCommands);
            }else{
                this.$notify({
                    type: 'error',
                    title: 'no services selected',
                });
            }
        },
        disableNotifications(){
            let externalCommands = [];
            if(this.uuids.length > 0){
                for(var i = 0; i < this.uuids.length; i++){
                    externalCommands.push({
                        serviceUuid: this.uuids[i].serviceUuid,
                        hostUuid: this.uuids[i].hostUuid,
                        command: 'DISABLE_SVC_NOTIFICATIONS',
                    });
                }
                this.$emit('masscommand', externalCommands);
            }else{
                this.$notify({
                    type: 'error',
                    title: 'no services selected',
                });
            }
        },
    }
}
</script>

<style scoped>

</style>
