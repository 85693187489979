<template>
    <b-button class="w-100 mb-1"
              @click="resetCheckTime" variant="outline-secondary">
        <i class="fa-solid fa-arrows-rotate"></i>
        {{ $t('action-items.reset-check-time') }}
    </b-button>
</template>

<script>
export default {
    name: "ResetCheckTime",
    props: [
        'uuids'
    ],
    methods: {
        resetCheckTime(){
            let externalCommands = [];
            if(this.uuids.length > 0){
                for(var i = 0; i < this.uuids.length; i++){
                    externalCommands.push({
                        serviceUuid: this.uuids[i].serviceUuid,
                        hostUuid: this.uuids[i].hostUuid,
                        command: 'SCHEDULE_FORCED_SVC_CHECK',
                    });
                }
                this.$emit('masscommand', externalCommands);
            }else{
                this.$notify({
                    type: 'error',
                    title: 'no services selected',
                });
            }
        },
    }
}
</script>

<style scoped>

</style>
