<template>
    <span>
        <span v-if="getAsBar === false">
            <span class="icon fa-solid fa-fire-flame-curved" v-bind:class="colorClass"></span>
        </span>
        <span v-if="getAsBar === true">
            <span v-for="(barColor, index) in barColors"
                  :key="index">
                <span v-if="isColorized(index)">
                    <span class="fa-solid fa-fire-flame-curved" v-bind:class="colorClass"></span>
                </span>
                <span v-if="!isColorized(index)">
                    <span class="fa-solid fa-fire-flame-curved"></span>
                </span>
            </span>
        </span>
    </span>
</template>

<script>
export default {
    name: "Priority",
    data(){
        return {
            barColors: [
                'ok-soft',
                'ok',
                'warning',
                'critical-soft',
                'critical'
            ],
            colorClass: ''
        }
    },
    props: {
        priority: {
            default: 1
        },
        asBar: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        getPriority: function(){
            return this.priority;
        },
        getAsBar: function(){
            return this.asBar;
        },

    },
    mounted(){
        this.getColor();
    },
    methods: {
        getColor: function(){
            let prio = this.getPriority;
            if(typeof (prio) == 'string'){
                prio = parseInt(prio, 10);
            }
            let color = this.barColors[(prio - 1)];

            this.colorClass = color;
        },
        isColorized: function(index){
            // index starts with 0! (default array index)
            // index 0 = priority 1
            // index 1 = priority 2 and so on
            let prio = this.getPriority;

            if(index <= (prio - 1)){
                return true;
            }
            return false;
        }
    }
}
</script>

<style scoped>

</style>
