<template>
    <div>
        <div>
            <div>
                <label class="typo__label">{{$t('forms.select-method')}}</label>
                <span :class="{ 'invalid is-invalid': $v.acknowledgmentSelected.$error }">
                    <multiselect v-model.trim="$v.acknowledgmentSelected.$model"
                                 track-by="value"
                                 :close-on-select="true"
                                 :show-labels="false"
                                 label="text"
                                 placeholder="Select one"
                                 :options="acknowledgementOptions"
                                 :searchable="false"
                                 :allow-empty="false">
                    </multiselect>
                </span>
                <div class="invalid-feedback" v-if="!$v.acknowledgmentSelected.required">{{ $t('Field is required') }}</div>
            </div>

            <b-form-group
                :label="$t('forms.message')"
                label-for="ackComment">
                <b-form-input id="ackComment" :class="{ 'is-invalid': $v.acknowledgementComment.$error }"
                              v-model.trim="$v.acknowledgementComment.$model" placeholder="Commment"></b-form-input>
                <div class="invalid-feedback" v-if="!$v.acknowledgementComment.required">{{ $t('Field is required') }}</div>
            </b-form-group>
            <b-form-group
                :description="$t('forms.sticky-description', {obj: 'host'})"
                label-for="sticky">
                <b-form-checkbox
                    id="sticky"
                    v-model="acknowledgementSticky"
                    name="stickyCheckbox"
                >
                    {{ $t('forms.sticky') }}
                </b-form-checkbox>
            </b-form-group>
            <b-form-group
                :description="$t('forms.notify-description', {obj: 'host'})"
                label-for="sticky">
                <b-form-checkbox
                    id="notify"
                    v-model="acknowledgementNotify"
                    name="notifyCheckbox"
                >
                    {{ $t('forms.notify') }}
                </b-form-checkbox>
            </b-form-group>
            <b-button variant="success" class="w-100" v-on:click="sendAcknowledgeCommand">{{ $t('forms.send-command') }}</b-button>
        </div>
    </div>
</template>

<script>

import Multiselect from "vue-multiselect";
import {required} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";

export default {
    name: "HostAcknowledge",
    components: {Multiselect},
    mixins: [validationMixin],
    data(){
        return {
            acknowledgmentSelected: {
                value: 'hostOnly',
                text: this.$t('hosts.individual')
            },
            acknowledgementOptions: [
                {
                    value: 'hostOnly',
                    text: this.$t('hosts.individual')
                },
                {
                    value: 'hostAndServices',
                    text: this.$t('hosts.including-services')
                }
            ],
            acknowledgementComment: '',
            acknowledgementSticky: false,
            acknowledgementNotify: false,
            removeServiceAcknowledgement: false,
        }
    },
    props: [
        'hostids',

    ],
    validations: {
        acknowledgmentSelected: {
            required
        },
        acknowledgementComment: {
            required
        },
    },
    methods: {
        sendAcknowledgeCommand: function(){
            if(this.hostids.length === 0){
                this.$notify({
                    type: 'error',
                    title: 'no hosts selected',
                })
                return
            }
            if(this.$v.$anyError || !this.$v.$anyDirty){
                this.$v.$touch()
                return;
            }

            let externalCommand = []
            for(let host in this.hostids){
                externalCommand.push({
                    command: 'ACKNOWLEDGE_HOST_PROBLEM',
                    hostUuid: this.hostids[host].hostUuid,
                    comment: this.acknowledgementComment,
                    sticky: this.acknowledgementSticky,
                    notify: this.acknowledgementNotify,
                    author: this.$store.state.user
                })
            }
            this.sendCommand(externalCommand);
        },
        sendCommand: function(externalCommand){
            let url = '/hosts/external_commands.json'
            let data = null
            if(this.acknowledgmentSelected.value === 'hostAndServices'){
                //at this point if we want to acknowledge all host services also, we have to get the services for the hosts in backend
                url = '/hosts/external_commands_mass_acknowledge_with_services.json'
                let hosts = []
                for(let host in this.hostids){
                    hosts.push(this.hostids[host].hostId)
                }
                data = {hostIds: hosts}
            }
                axios.post(url,externalCommand, {params: data})
                .then(response => {
                        if(response.data.response.code && response.data.response.code === 403) {
                            this.$notify({
                                type: 'error',
                                title: 'insufficient rights',
                                text: 'Action forbidden for this user',
                                duration: 3000
                            });
                        } else {
                            this.$notify({
                                type: 'success',
                                title: '',
                                text: this.$t('Command successfully sent'),
                                duration: 7000
                            });
                        }
                        this.$emit('refresh')
                })
                .catch(error => {
                    this.$notify({
                        type: 'error',
                        title: this.$t('error'),
                    });
                });
        }
    },
    computed: {
        getUuid: function(){
            return this.hostids;
        },

    }
}
</script>

<style scoped>

</style>
