<template>
    <div>
        <div class="accordion" role="tablist">
            <b-button block v-b-toggle.hostScheduleResetCheckTime variant="outline-secondary" class="mb-1">
                <i class="fa-solid fa-arrows-rotate"></i>
                {{ $t('action-items.reset-check-time') }}
            </b-button>
            <b-collapse id="hostScheduleResetCheckTime" accordion="my-accordion" role="tabpanel" class="mt-2 mb-2">
                <b-list-group-item>
                    <host-reset-check-time :hostids="hostids" @refresh="actionRefresh()"></host-reset-check-time>
                </b-list-group-item>
            </b-collapse>
            <b-button block v-b-toggle.hostScheduleMaintenance variant="outline-secondary" class="mb-1">
                <i class="fa-solid fa-power-off"></i>
                {{ $t('action-items.schedule-maintenance') }}
            </b-button>
            <b-collapse id="hostScheduleMaintenance" accordion="my-accordion" role="tabpanel" class="mt-2 mb-2">
                <b-list-group-item>
                    <host-downtime :hostids="hostids" @refresh="actionRefresh()"></host-downtime>
                </b-list-group-item>
            </b-collapse>
            <b-button block v-b-toggle.hostAcknowledge variant="outline-secondary" class="mb-1">
                <i class="fa-solid fa-user"></i>
                {{ $t('action-items.acknowledge-host-status') }}
            </b-button>
            <b-collapse id="hostAcknowledge" accordion="my-accordion" role="tabpanel" class="mt-2 mb-2">
                <b-list-group-item>
                    <host-acknowledge :hostids="hostids" @refresh="actionRefresh()"></host-acknowledge>
                </b-list-group-item>
            </b-collapse>
            <b-button block v-b-toggle.hostDisableNotifications variant="outline-secondary" class="mb-1">
                <i class="fa-solid fa-envelope"></i>
                {{ $t('action-items.notifications-disable') }}
            </b-button>
            <b-collapse id="hostDisableNotifications" accordion="my-accordion" role="tabpanel" class="mt-2 mb-2">
                <b-list-group-item>
                    <host-notifications-disable :hostids="hostids"
                                                @refresh="actionRefresh()"></host-notifications-disable>
                </b-list-group-item>
            </b-collapse>
            <b-button block v-b-toggle.hostEnableNotifications variant="outline-secondary" class="mb-1">
                <i class="fa-solid fa-envelope"></i>
                {{ $t('action-items.notifications-enable') }}
            </b-button>
            <b-collapse id="hostEnableNotifications" accordion="my-accordion" role="tabpanel" class="mt-2 mb-2">
                <b-list-group-item>
                    <host-notifications-enable :hostids="hostids"
                                               @refresh="actionRefresh()"></host-notifications-enable>
                </b-list-group-item>
            </b-collapse>
        </div>
    </div>
</template>

<script>
import HostAcknowledge from "./MassHostAcknowledge";
import HostDowntime from "./MassHostDowntime";
import HostResetCheckTime from "./MassHostResetCheckTime";
import HostNotificationsEnable from "./MassHostNotificationsEnable";
import HostNotificationsDisable from "./MassHostNotificationsDisable";

export default {
    name: "HostExternalCommands",
    components: {HostAcknowledge, HostDowntime, HostResetCheckTime, HostNotificationsEnable, HostNotificationsDisable},
    props: [
        'hostids',

    ],

    methods: {
        actionRefresh(){
            setTimeout(() => {
                this.$emit('refresh-event')
            }, 7000);
        }
    }
}
</script>

<style scoped>

</style>
