<template>
    <div>
        <div>
            <b-form-group
                :label="$t('forms.downtimetype')"
                label-for="downtimeType">
                <b-form-select id="downtimeType" v-model="downtimeTypeSelected"
                               :options="downtimetypeOptions"></b-form-select>
            </b-form-group>
            <b-form-group
                :label="$t('comment')"
                label-for="downtimeComment">
                <b-form-input id="downtimeComment" :class="{ 'is-invalid': $v.downtimeComment.$error }"
                              v-model.trim="$v.downtimeComment.$model" placeholder="Commment"></b-form-input>
                <div class="invalid-feedback" v-if="!$v.downtimeComment.required">{{ $t('Field is required') }}</div>
            </b-form-group>
            <b-form-group>
                <label for="downtimeStart">Start</label>
                <b-form-input :class="{ 'is-invalid': $v.downtimeStartTime.$error }"
                              v-model.trim="$v.downtimeStartTime.$model" type="datetime-local"></b-form-input>
                <div class="invalid-feedback" v-if="!$v.downtimeStartTime.required">{{ $t('Field is required') }}</div>
            </b-form-group>
            <b-form-group>
                <label for="downtimeStart">{{ $t('end') }}</label>
                <b-form-input :class="{ 'is-invalid': $v.downtimeEndTime.$error }"
                              v-model.trim="$v.downtimeEndTime.$model" type="datetime-local"></b-form-input>
                <div class="invalid-feedback" v-if="!$v.downtimeEndTime.required">{{ $t('Field is required') }}</div>
            </b-form-group>

            <b-button variant="success" class="w-100" v-on:click="sendDowntimeCommand">{{ $t('forms.send-command') }}</b-button>
        </div>
    </div>
</template>

<script>

/*
SCHEDULE_HOST_DOWNTIME
SCHEDULE_HOST_SVC_DOWNTIME
SCHEDULE_AND_PROPAGATE_HOST_DOWNTIME
SCHEDULE_AND_PROPAGATE_TRIGGERED_HOST_DOWNTIME


 */

import {required} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";

export default {
    name: "HostDowntime",
    mixins: [validationMixin],
    data(){
        return {
            downtimeTypeSelected: 'SCHEDULE_HOST_DOWNTIME',
            downtimetypeOptions: [
                {
                    value: 'SCHEDULE_HOST_DOWNTIME',
                    text: this.$t('hosts.individual')
                },
                {
                    value: 'SCHEDULE_HOST_SVC_DOWNTIME',
                    text: this.$t('hosts.including-services')
                },
                {
                    value: 'SCHEDULE_AND_PROPAGATE_HOST_DOWNTIME',
                    text: this.$t('hosts.dependent-not-triggered')
                },
                {
                    value: 'SCHEDULE_AND_PROPAGATE_TRIGGERED_HOST_DOWNTIME',
                    text: this.$t('hosts.dependent-triggered')
                }
            ],
            downtimeComment: 'in progress',
            downtimeStartTime: '',
            downtimeEndTime: '',
            removeServiceDowntimes: true
        }
    },
    props: [
        'hostids',
    ],
    validations: {
        downtimeTypeSelected: {
            required
        },
        downtimeComment: {
            required,
        },
        downtimeStartTime: {
            required
        },
        downtimeEndTime: {
            required
        }
    },
    methods: {
        sendDowntimeCommand: function(){
            if(this.hostids.length === 0){
                this.$notify({
                    type: 'error',
                    title: 'no hosts selected',
                })
                return
            }
            this.$v.$touch();
            if(this.$v.$anyError || !this.$v.$anyDirty){
                return;
            }
            let externalCommand = []
            for(let host in this.hostids){
                externalCommand.push({
                    command: this.downtimeTypeSelected,
                    hostUuid: this.hostids[host].hostUuid,
                    comment: this.downtimeComment,
                    start_time: this.downtimeStartTime,
                    end_time: this.downtimeEndTime,
                    fixed: 1,
                    author: this.$store.state.user
                })
                if(this.downtimeTypeSelected.value === 'SCHEDULE_HOST_SVC_DOWNTIME'){
                    // we need to trigger the SCHEDULE_HOST_DOWNTIME command as the SCHEDULE_HOST_SVC_DOWNTIME sets all
                    // services from the host into a downtime except the host itself
                    externalCommand.push({
                        command: 'SCHEDULE_HOST_DOWNTIME',
                        hostUuid: this.hostids[host].hostUuid,
                        comment: this.downtimeComment,
                        start_time: this.downtimeStartTime,
                        end_time: this.downtimeEndTime,
                        fixed: 1,
                        author: this.$store.state.user
                    })
                }
            }

            //externalCommand = JSON.stringify(Object.assign({}, externalCommand));
            this.sendCommand(externalCommand);
        },
        sendCommand: function(externalCommand){
           /* axios.post('/hosts/external_commands.json', null, {
                params: {
                    externalCommand: externalCommand,
                }
            }) */
            axios.post('/hosts/external_commands.json', externalCommand)
                .then(response => {
                        if(response.data.response.code && response.data.response.code === 403) {
                            this.$notify({
                                type: 'error',
                                title: 'insufficient rights',
                                text: 'Action forbidden for this user',
                                duration: 3000
                            });
                        } else {
                            this.$notify({
                                type: 'success',
                                title: '',
                                text: this.$t('Command successfully sent'),
                                duration: 7000
                            });
                        }
                        this.$emit('refresh')

                })
                .catch(error => {
                    this.$notify({
                        type: 'error',
                        title: this.$t('error'),
                    });
                });
        },
        getDates(){
            axios.post('/downtimes/get_dates.json')
                .then(response => {
                        let datetime = response.data.datetime[0];
                        this.downtimeStartTime = datetime.start_time;
                        this.downtimeEndTime = datetime.end_time;
                })
                .catch(error => {
                    console.log('error: ' + error)
                });
        },
    },
    mounted(){
        this.getDates();
    },
    computed: {
        getUuid: function(){
            return this.hostids;
        },
    }
}
</script>

<style scoped>

</style>
