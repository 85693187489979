import Vue from 'vue';
import Notifications from 'vue-notification';
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import './sliding-pagination.css';
import axios from 'axios';
import {BootstrapVue, BootstrapVueIcons} from 'bootstrap-vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import App from './views/App.vue';
import VueI18n from "vue-i18n";

Vue.use(VueLoading);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(Notifications);

const instance = axios.create({
    withCredentials: true
});
window.axios = instance;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.interceptors.response.use(function(response){
    return response;
}, function(error){
    if(error.response.status === 401){
       /* Vue.notify({
            type: 'error',
            title: 'Authorization',
            text: 'You have been logged out, session expired?'
        }); */

        //this.$store.commit('setState', false);
        localStorage.removeItem('status');

        if(router.currentRoute.name !== 'login'){
            router.push({name: 'login'});
        }
        //return error.response;
        return Promise.reject(error)


    }else{
        //return error.response;
        return Promise.reject(error)
    }
});

const app = new Vue({
    el: '#app',
    data: function(){
        return {
            theme: 'light'
        };
    },
    mounted: function(){
        this.toggleDarkMode();
    },
    components: {
        App
    },
    i18n,
    router,
    store,
    methods: {
        toggleDarkMode: function(){

            // https://forum.vuejs.org/t/how-do-i-add-remove-classes-to-body/1219
            if(this.theme === 'dark'){
                document.documentElement.classList.add('theme-dark');
            }else{
                // Default light theme
                document.documentElement.classList.remove('theme-dark');
            }
        },
    },
    watch: {
        theme(newValue){
            this.toggleDarkMode();
            localStorage.setItem('theme', newValue);
            this.$store.commit('setTheme', newValue);
        }
    },
    created: function(){
        if(this.$store.state.theme === ''){
            if(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches){
                this.theme = 'dark';
            }
            localStorage.setItem('theme', this.theme);
            this.$store.commit('setTheme', this.theme);

        }else{
            this.theme = this.$store.state.theme;
        }
    }
});
