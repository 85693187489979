<template>
    <div>
    <notifications position="top center" width="50%"/>
    <index></index>
    </div>
</template>

<script>
import Index from '../components/Index.vue';

export default {
    components: {
        'index': Index,
    },
}

</script>
