<template>
 <span
     class="icon badge border" role="button"
     @click="switchValue"
     style="min-width: 160px;"
     v-bind:class="['border-'+css_class, css_class, 'shadow-'+css_class]">
     {{ time_str }}
 </span>
</template>

<script>
export default {
    name: "DateSwitch",
    data(){
        return {
            date_time_str: '',
            time_str: '',
            inWords: true
        }
    },
    props: {
        css_class: String,
        status: Object
    },
    computed: {},
    mounted(){
        this.time_str = this.status.last_state_change_in_words;
    },
    methods: {
        switchValue: function(){
            if(this.inWords){
                // Display as "11:24:31 - 29.11.2019"
                this.time_str = this.status.last_state_change;
                this.inWords = false;
            }else{
                // Display as "2Y 10M 13D 1h 23m 18s"
                this.time_str = this.status.last_state_change_in_words;
                this.inWords = true;
            }
        },
    }
}
</script>

<style scoped>

</style>
