<template>
    <div class="mt-2" ref="loader">
        <b-container class="container-lg">
            <b-row class="p-0">
                <b-col class="text-left text-truncate col-7">
                    <h5>
                        <i class="fa-solid fa-power-off"></i>
                        {{ $t('Service') }} {{ $t('downtimes.downtimes') }}
                    </h5>
                </b-col>

                <b-col class="text-right col-5">
                    <b-button-group>
                        <b-button variant="success" size="sm" link :to="{path:'/downtimes/services_add'}"
                                  v-show="actionPermissionsAdd">
                            <i class="fa-solid fa-plus"></i>
                            {{ $t('downtimes.add') }}
                        </b-button>
                        <button type="button" class="btn btn-light btn-sm" @click="refreshData">
                            <i class="fa-solid fa-arrows-rotate"
                               :class="{'fa-spin': loading}"></i>
                        </button>
                    </b-button-group>
                </b-col>
            </b-row>

            <b-tabs fill class="downtimeNavTabs mt-3">
                <!-- service downtimes START -->
                <b-tab ref="tab1" @click="getDowntimes(paging.page)">
                    <template #title>
                        <i class="fa-solid fa-power-off"></i>
                        <p class="mb-1">{{ $t('downtimes.downtimes') }}</p>
                    </template>
                    <b-list-group>
                        <b-list-group-item>
                            <div class="row">
                                <div class="col">
                                    <b-button v-b-toggle.filter class="m-0 w-100" variant="primary">
                                        <i class="fa-solid fa-filter"></i>
                                        {{ $t('Filter') }}
                                    </b-button>
                                </div>
                            </div>
                            <b-collapse id="filter">
                                <div class="d-flex w-100 mt-2">
                                    <b-form-input v-model="hostnameFilter" :placeholder="$t('hostname')"></b-form-input>
                                </div>
                                <div class="d-flex w-100 mt-2">
                                    <b-form-input v-model="servicenameFilter"
                                                  :placeholder="$t('servicename')"></b-form-input>
                                </div>
                                <div class="d-flex w-100 mt-2">
                                    <b-form-input v-model="commentFilter" :placeholder="$t('comment')"></b-form-input>
                                </div>
                                <div class="d-flex w-100 mt-2">
                                    <b-form-input v-model="userFilter" :placeholder="$t('user')"></b-form-input>
                                </div>
                                <div class="d-flex w-100 mt-2">
                                    <b-form-group class="w-100">
                                        <label>{{ $t('Start') }}</label>
                                        <b-form-input v-model="downtimeFrom"
                                                      type="datetime-local"></b-form-input>
                                    </b-form-group>
                                </div>
                                <div class="d-flex w-100 mt-2">
                                    <b-form-group class="w-100">
                                        <label>{{ $t('end') }}</label>
                                        <b-form-input v-model="downtimeTo"
                                                      type="datetime-local"></b-form-input>
                                    </b-form-group>
                                </div>

                                <b-form-group
                                    label-for="isRunning">
                                    <b-form-checkbox
                                        switch
                                        id="isRunning"
                                        v-model="isRunningFilter"
                                        name="isRunningDowntimeFilter"
                                    >
                                        {{ $t('filter.is-running') }}
                                    </b-form-checkbox>
                                </b-form-group>
                                <b-form-group
                                    label-for="wasNotCancelled">
                                    <b-form-checkbox
                                        switch
                                        id="wasNotCancelled"
                                        v-model="wasNotCancelledFilter"
                                        name="wasNotCancelledDowntimeFilter"
                                    >
                                        {{ $t('filter.was-not-cancelled') }}
                                    </b-form-checkbox>
                                </b-form-group>
                                <b-form-group
                                    label-for="wasCancelled">
                                    <b-form-checkbox
                                        switch
                                        id="wasCancelled"
                                        v-model="wasCancelledFilter"
                                        name="wasCancelledDowntimeFilter"
                                    >
                                        {{ $t('filter.was-cancelled') }}
                                    </b-form-checkbox>
                                </b-form-group>
                                <b-form-group
                                    label-for="hideExpired">
                                    <b-form-checkbox
                                        switch
                                        id="hideExpired"
                                        v-model="hideExpiredFilter"
                                        name="hideExpiredDowntimeFilter"
                                    >
                                        {{ $t('filter.hide-expired') }}
                                    </b-form-checkbox>
                                </b-form-group>

                                <div class="col">
                                    <b-button class="w-100" @click="resetDowntimeFilter"
                                              variant="outline-danger">
                                        {{ $t('filter.reset-filter') }}
                                    </b-button>
                                </div>
                            </b-collapse>
                        </b-list-group-item>  <!-- Filter end -->
                    </b-list-group>

                    <!-- start new list -->
                    <b-list-group
                        v-for="serviceDowntime in serviceDowntimes.downtimes"
                        v-bind:key="serviceDowntime.DowntimeService.internalDowntimeId"
                        class="mt-2">

                        <div class="flex-column align-items-start">
                            <div class="list-group-item flex-column align-items-start list-group-item-action pl-0">

                                <div class="d-flex flex-row min-h-110">
                                    <div class="p-2">
                                        <downtime-state
                                            v-bind:downtime="serviceDowntime.DowntimeService"></downtime-state>
                                    </div>
                                    <div class="min-width-0">
                                        <div class="row">
                                            <div class="col-12 text-right">
                                                <span
                                                    v-show="serviceDowntime.DowntimeService.isRunning"
                                                    class="font-italic downtime-running">
                                                    <i class="fa-solid fa-play"></i>
                                                    {{ $t('Running') }}
                                                </span>

                                                <span
                                                    v-show="serviceDowntime.DowntimeService.isExpired"
                                                    class="font-italic downtime-expired">
                                                    <i class="fa-solid fa-stop"></i>
                                                    {{ $t('Expired') }}
                                                </span>

                                                <span
                                                    v-show="serviceDowntime.DowntimeService.wasCancelled"
                                                    class="font-italic downtime-cancelled">
                                                    <i class="fa-solid fa-ban"></i>
                                                    {{ $t('Was cancelled') }}
                                                </span>

                                                <span
                                                    v-show="!serviceDowntime.DowntimeService.wasCancelled && !serviceDowntime.DowntimeService.wasStarted && !serviceDowntime.DowntimeService.isExpired"
                                                    class="font-italic downtime-upcoming">
                                                    <i class="fa-regular fa-clock"></i>
                                                    {{ $t('Upcoming') }}
                                                </span>

                                            </div>
                                        </div>
                                        <div class="row p-2">
                                            <div class="col-12 text-truncate h5">
                                                <router-link
                                                    :to="{name: 'servicesDetails', params: {id: serviceDowntime.Service.id}}">
                                                    {{ serviceDowntime.Service.servicename }}
                                                </router-link>
                                            </div>
                                            <div class="col-12 text-truncate">
                                                <div class="row">
                                                    <div class="col-12 text-truncate">
                                                        <i class="fa-solid fa-desktop"></i>
                                                        {{ serviceDowntime.Host.hostname }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 text-truncate">
                                                <div class="row">
                                                    <div class="col-12 text-truncate">
                                                        <i class="fa-solid fa-comment-dots"></i>
                                                        {{ serviceDowntime.DowntimeService.commentData }}
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-12 text-truncate pt-1"
                                                 v-show="serviceDowntime.DowntimeService.isRunning">
                                                {{ $t('Until') }}:
                                                {{ serviceDowntime.DowntimeService.scheduledEndTime }}
                                                <br/>
                                                {{ $t('Duration') }}:
                                                {{ serviceDowntime.DowntimeService.durationHuman }}
                                            </div>

                                            <div class="col-12 text-truncate pt-1"
                                                 v-show="serviceDowntime.DowntimeService.isExpired">
                                                {{ $t('Ended') }}:
                                                {{ serviceDowntime.DowntimeService.scheduledEndTime }}
                                            </div>

                                            <div class="col-12 text-truncate pt-1"
                                                 v-show="serviceDowntime.DowntimeService.wasCancelled">
                                                {{ $t('Cancelled') }}:
                                                {{ serviceDowntime.DowntimeService.actualEndTime }}
                                            </div>

                                            <div class="col-12 text-truncate pt-1"
                                                 v-show="!serviceDowntime.DowntimeService.wasCancelled && !serviceDowntime.DowntimeService.wasStarted && !serviceDowntime.DowntimeService.isExpired">
                                                {{ $t('Scheduled') }}:
                                                {{ serviceDowntime.DowntimeService.scheduledStartTime }}
                                                <br/>
                                                {{ $t('Duration') }}:
                                                {{ serviceDowntime.DowntimeService.durationHuman }}
                                            </div>

                                        </div>

                                        <div class="row"
                                             v-show="actionPermissionsDelete && serviceDowntime.DowntimeService.allowEdit && serviceDowntime.DowntimeService.isCancellable">
                                            <div class="col-12 text-right">
                                                <b-button
                                                    v-on:click="selectDowntime(serviceDowntime)"
                                                    v-b-modal.cancelDowntimeModal
                                                    size="sm"
                                                    variant="outline-danger">
                                                    <i class="fa-solid fa-trash"></i>
                                                    {{ $t('Cancel') }}
                                                </b-button>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </b-list-group>
                    <!-- end new list -->

                    <b-list-group class="mt-3">
                        <div v-if="serviceDowntimes.downtimes.length > 0">
                            <div class="d-flex w-100 justify-content-between">
                                <small>{{ $t('page') }} {{ paging.page }}</small>
                                <small>{{ $t('pagesize') }} {{ paging.limit }}</small>
                            </div>
                            <div class="d-flex w-100 justify-content-center mt-4">
                                <sliding-pagination
                                    :current="paging.page"
                                    :total="paging.pageCount"
                                    @page-change="getDowntimes">
                                </sliding-pagination>
                            </div>
                        </div>
                        <b-list-group-item v-if="serviceDowntimes.downtimes.length === 0">
                            <h5 class="mb-1">{{ $t('noitems') }}</h5>
                        </b-list-group-item>
                    </b-list-group>

                </b-tab>
                <!-- service downtimes END -->

                <!-- Recurring service downtimes START -->
                <b-tab ref="tab4" @click="getRecurringDowntimes(recurringPaging.page)">
                    <template #title>
                        <i class="fa-solid fa-clock-rotate-left fa-flip-horizontal ok"></i>
                        <p class="mb-1">{{ $t('downtimes.recurring') }}</p>
                    </template>
                    <b-list-group>
                        <b-list-group-item>
                            <div class="row">
                                <div class="col">
                                    <b-button v-b-toggle.filter class="m-0 w-100" variant="primary">
                                        <i class="fa-solid fa-filter"></i>
                                        {{ $t('Filter') }}
                                    </b-button>
                                </div>
                            </div>
                            <b-collapse id="filter">
                                <div class="d-flex w-100 mt-2">
                                    <b-form-input v-model="hostnameRecurringFilter"
                                                  :placeholder="$t('hostname')"></b-form-input>
                                </div>
                                <div class="d-flex w-100 mt-2">
                                    <b-form-input v-model="servicenameRecurringFilter"
                                                  :placeholder="$t('servicename')"></b-form-input>
                                </div>
                                <div class="d-flex w-100 mt-2">
                                    <b-form-input v-model="commentRecurringFilter"
                                                  :placeholder="$t('comment')"></b-form-input>
                                </div>
                                <div class="d-flex w-100 mt-2">
                                    <b-form-input v-model="userRecurringFilter"
                                                  :placeholder="$t('user')"></b-form-input>
                                </div>

                                <div class="col mt-2">
                                    <b-button class="w-100" @click="resetRecurringFilter" variant="outline-danger">
                                        {{ $t('filter.reset-filter') }}
                                    </b-button>
                                </div>
                            </b-collapse>
                        </b-list-group-item> <!-- Filter recurring end -->
                    </b-list-group>

                    <!-- start new recurring list -->
                    <b-list-group
                        v-for="recurringServiceDowntime in serviceDowntimes.recurring"
                        v-bind:key="recurringServiceDowntime.Systemdowntime.id"
                        downtime="recurringServiceDowntime"
                        v-b-modal.recurringDowntimeModal
                        v-on:click="selectDowntime(recurringServiceDowntime)"
                        class="mt-2">

                        <div class="flex-column align-items-start">
                            <div class="list-group-item flex-column align-items-start list-group-item-action pl-0">

                                <div class="d-flex flex-row min-h-110">
                                    <div class="p-2">
                                        <div class="h-100 status-line bg-info shadow-info"></div>
                                    </div>
                                    <div class="min-width-0">
                                        <div class="row">
                                            <div class="col-12 text-right">

                                                <span
                                                    class="font-italic text-info">
                                                    {{ $t('Recurring') }}</span>

                                            </div>
                                        </div>
                                        <div class="row p-2">
                                            <div class="col-12 text-truncate h5">
                                                <router-link
                                                    :to="{name: 'servicesDetails', params: {id: recurringServiceDowntime.Service.id}}">
                                                    {{ recurringServiceDowntime.Service.servicename }}
                                                </router-link>
                                            </div>
                                            <div class="col-12 text-truncate">
                                                <div class="row">
                                                    <div class="col-12 text-truncate">
                                                        <i class="fa-solid fa-desktop"></i>
                                                        {{ recurringServiceDowntime.Host.hostname }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 text-truncate">
                                                <div class="row">
                                                    <div class="col-12 text-truncate">
                                                        <i class="fa-solid fa-comment-dots"></i>
                                                        {{ recurringServiceDowntime.Systemdowntime.comment }}
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-12 text-truncate pt-1">
                                                {{ $t('Start') }}:
                                                {{ recurringServiceDowntime.Systemdowntime.startTime }}
                                                <br/>
                                                {{ $t('Duration') }}:
                                                {{ recurringServiceDowntime.Systemdowntime.duration }}
                                                {{ $t('minutes') }}
                                                <br/>
                                                {{ $t('Creator') }}:
                                                {{ recurringServiceDowntime.Systemdowntime.author }}
                                            </div>

                                        </div>

                                        <div class="row"
                                             v-show="actionPermissionsDelete && recurringServiceDowntime.Host.allow_edit">
                                            <div class="col-12 text-right">
                                                <b-button
                                                    v-on:click="selectDowntime(recurringServiceDowntime)"
                                                    v-b-modal.recurringDowntimeModal
                                                    size="sm"
                                                    variant="outline-danger">
                                                    <i class="fa-solid fa-trash"></i>
                                                    {{ $t('Delete') }}
                                                </b-button>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </b-list-group>
                    <!-- end new recurring list -->

                    <b-list-group class="mt-3">
                        <div v-if="serviceDowntimes.recurring.length > 0">
                            <div class="d-flex w-100 justify-content-between">
                                <small>{{ $t('page') }} {{ recurringPaging.page }}</small>
                                <small>{{ $t('pagesize') }} {{ recurringPaging.limit }}</small>
                            </div>
                            <div class="d-flex w-100 justify-content-center mt-4">
                                <sliding-pagination
                                    :current="recurringPaging.page"
                                    :total="recurringPaging.pageCount"
                                    @page-change="getDowntimes">
                                </sliding-pagination>
                            </div>
                        </div>
                        <b-list-group-item v-if="serviceDowntimes.recurring.length === 0">
                            <h5 class="mb-1">{{ $t('noitems') }}</h5>
                        </b-list-group-item>
                    </b-list-group>

                </b-tab>
                <!-- Recurring service downtimes END -->
            </b-tabs>
            <!-- Cancel service downtime modal START -->
            <div>
                <b-modal
                    id="cancelDowntimeModal"
                    ref="cancelDowntimeModal"
                    :title="$t('forms.attention')"
                    header-bg-variant="danger"
                    header-text-variant="white">
                    <h6 class="text-danger">{{ $t('downtimes.confirm-cancel-downtime-service') }}</h6>
                    <div v-if="selectedDowntime.Host != null && selectedDowntime.DowntimeService != null">

                        <dl>
                            <dt>{{ $t('Hostname') }}</dt>
                            <dd class="text-truncate">
                                <router-link :to="{name: 'hostsDetails', params: {id: selectedDowntime.Host.id}}">
                                    {{ selectedDowntime.Host.hostname }}
                                </router-link>
                            </dd>

                            <dt>{{ $t('Servicename') }}</dt>
                            <dd class="text-truncate">
                                <router-link :to="{name: 'servicesDetails', params: {id: selectedDowntime.Service.id}}">
                                    {{ selectedDowntime.Service.servicename }}
                                </router-link>
                            </dd>

                            <dt>{{ $t('User') }}</dt>
                            <dd class="text-truncate">{{ selectedDowntime.DowntimeService.authorName }}</dd>

                            <dt>{{ $t('comment') }}</dt>
                            <dd class="text-truncate">{{ selectedDowntime.DowntimeService.commentData }}</dd>

                            <dt>{{ $t('Start') }}</dt>
                            <dd>{{ selectedDowntime.DowntimeService.scheduledStartTime }}</dd>

                            <dt>{{ $t('End') }}</dt>
                            <dd>{{ selectedDowntime.DowntimeService.scheduledEndTime }}</dd>

                            <dt>{{ $t('duration') }}</dt>
                            <dd>{{ selectedDowntime.DowntimeService.durationHuman }}</dd>
                        </dl>
                    </div>

                    <template #modal-footer="{ ok, cancel, hide }">
                        <b-button class="mr-auto" size="sm" variant="danger" @click="cancelDowntime(); hide();"
                                  v-show="actionPermissionsSystemDelete">
                            <i class="fa-solid fa-trash"></i>
                            {{ $t('cancel-downtime') }}
                        </b-button>

                        <b-button size="sm" variant="secondary" @click="ok()">
                            {{ $t('forms.close') }}
                        </b-button>
                    </template>

                </b-modal>
            </div>
            <!-- Cancel service downtime modal END -->
            <!-- Recurring downtime modal START -->
            <div>
                <b-modal
                    id="recurringDowntimeModal"
                    header-bg-variant="info"
                    header-text-variant="white"
                    :title="$t('Recurring downtime Info')">
                    <div v-if="selectedDowntime.Systemdowntime != null">
                        <dl>
                            <dt>{{ $t('Hostname') }}</dt>
                            <dd>
                                <router-link :to="{name: 'hostsDetails', params: {id: selectedDowntime.Host.id}}">
                                    {{ selectedDowntime.Host.hostname }}
                                </router-link>
                            </dd>

                            <dt>{{ $t('Servicename') }}</dt>
                            <dd>
                                <router-link :to="{name: 'servicesDetails', params: {id: selectedDowntime.Service.id}}">
                                    {{ selectedDowntime.Service.servicename }}
                                </router-link>
                            </dd>

                            <dt class="text-truncate">{{ $t('User') }}</dt>
                            <dd>{{ selectedDowntime.Systemdowntime.author }}</dd>

                            <dt class="text-truncate">{{ $t('comment') }}</dt>
                            <dd>{{ selectedDowntime.Systemdowntime.comment }}</dd>

                            <dt>{{ $t('forms.weekdays') }}</dt>
                            <dd>
                                <b-badge :variant="getSelectedWeekdays(selectedDowntime.Systemdowntime.weekdays, '1')">
                                    {{ $t('days.monday') }}
                                </b-badge>
                                <b-badge :variant="getSelectedWeekdays(selectedDowntime.Systemdowntime.weekdays, '2')">
                                    {{ $t('days.tuesday') }}
                                </b-badge>
                                <b-badge :variant="getSelectedWeekdays(selectedDowntime.Systemdowntime.weekdays, '3')">
                                    {{ $t('days.wednesday') }}
                                </b-badge>
                                <b-badge :variant="getSelectedWeekdays(selectedDowntime.Systemdowntime.weekdays, '4')">
                                    {{ $t('days.thursday') }}
                                </b-badge>
                                <b-badge :variant="getSelectedWeekdays(selectedDowntime.Systemdowntime.weekdays, '5')">
                                    {{ $t('days.friday') }}
                                </b-badge>
                                <b-badge :variant="getSelectedWeekdays(selectedDowntime.Systemdowntime.weekdays, '6')">
                                    {{ $t('days.saturday') }}
                                </b-badge>
                                <b-badge :variant="getSelectedWeekdays(selectedDowntime.Systemdowntime.weekdays, '7')">
                                    {{ $t('days.sunday') }}
                                </b-badge>
                            </dd>

                            <dt>{{ $t('forms.monthdays') }}</dt>
                            <dd>
                                <b-badge v-for="(dayOfMonthItem, index) in selectedDowntime.Systemdowntime.dayOfMonth"
                                         :key="index"
                                         :variant="'success'"
                                         class="mr-1">{{ dayOfMonthItem }}
                                </b-badge>
                                <span v-if="selectedDowntime.Systemdowntime.dayOfMonth.length == 0" class="font-italic">
                                    {{ $t('none') }}
                                </span>
                            </dd>

                            <dt>{{ $t('Start') }}</dt>
                            <dd>{{ selectedDowntime.Systemdowntime.startTime }} {{ $t('minutes') }}</dd>

                            <dt>{{ $t('duration') }}</dt>
                            <dd>{{ selectedDowntime.Systemdowntime.duration }} {{ $t('minutes') }}</dd>
                        </dl>

                    </div>
                    <template #modal-footer="{ ok, cancel, hide }">
                        <b-button class="mr-auto" size="sm" variant="danger" @click="cancelDowntime(); hide();"
                                  v-show="actionPermissionsSystemDelete">
                            <i class="fa-solid fa-trash"></i>
                            {{ $t('cancel-downtime') }}
                        </b-button>

                        <b-button size="sm" variant="secondary" @click="ok()">
                            {{ $t('forms.close') }}
                        </b-button>
                    </template>
                </b-modal>
            </div>
            <!-- Recurring downtime modal END -->
        </b-container>
    </div>
</template>

<script>
import SlidingPagination from 'vue-sliding-pagination'
import {debounce} from "../../helpers";
import DowntimeState from "../utils/DowntimeState";

export default {
    name: "DowntimesServices",
    components: {DowntimeState, SlidingPagination},
    data(){
        let now = new Date();
        let from = new Date(now.getTime() - (3600 * 24 * 30 * 1000));
        let to = new Date(now.getTime() + (3600 * 24 * 30 * 2 * 1000));
        return {
            init: false,
            loading: true,
            serviceDowntimes: {
                downtimes: [],
                recurring: []
            },
            selectedDowntime: {},
            paging: {
                page: 1,
                pageCount: 1,
            },
            recurringPaging: {
                page: 1,
                pageCount: 1,
            },
            downtimeFilter: {
                filter: {
                    hideExpired: true,
                    isRunning: '',
                    was_cancelled: '',
                    authorName: '',
                    commentData: '',
                    hostname: '',
                    servicename: '',
                    from: from.toLocaleString('de-DE', {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit"
                    }).replace(',', ''),
                    to: to.toLocaleString('de-DE', {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit"
                    }).replace(',', '')
                }
            },
            hostnameFilter: '',
            servicenameFilter: '',
            commentFilter: '',
            userFilter: '',
            downtimeFrom: '',
            downtimeTo: '',
            isRunningFilter: '',
            hideExpiredFilter: true,
            wasCancelledFilter: false,
            wasNotCancelledFilter: '',
            hostnameRecurringFilter: '',
            servicenameRecurringFilter: '',
            commentRecurringFilter: '',
            userRecurringFilter: '',
            recurringFilter: {
                filter: {
                    hostname: '',
                    servicename: '',
                    author: '',
                    comment: ''
                }
            },
            permissions: {
                downtimes: {},
                systemdowntimes: {}
            }
        };
    },
    methods: {
        selectDowntime: function(serviceDowntime){
            this.selectedDowntime = serviceDowntime;
        },
        cancelDowntime: function(){
            let downtimeId = null;
            let downtimeType = null;
            if(this.selectedDowntime.hasOwnProperty('Systemdowntime')){
                //recurring downtime
                downtimeId = this.selectedDowntime.Systemdowntime.id;
                downtimeType = 'Systemdowntime';
            }else{
                //normal downtime
                downtimeId = this.selectedDowntime.DowntimeService.internalDowntimeId;
                downtimeType = 'downtime';
            }

            axios.post('/downtimes/delete.json', {
                downtimeType: downtimeType,
                downtimeId: downtimeId,
                isServiceRequest: true
            })
                .then(response => {
                        if(response.data.response.hasOwnProperty('error')){
                            this.$notify({
                                type: 'error',
                                title: this.$t('error'),
                            });
                            return;
                        }
                        if(response.data.response.hasOwnProperty('success')){
                            if(response.data.response.success === false){
                                let errormsg = this.$t('error');
                                if(response.data.response.hasOwnProperty('message')){
                                    errormsg = response.data.response.message;
                                }
                                this.$notify({
                                    type: 'error',
                                    text: errormsg,
                                });
                                return;
                            }
                        }

                        this.$notify({
                            type: 'success',
                            title: 'Downtime successfully cancelled',
                            text: 'Data reload in 7 seconds',
                            duration: 7000
                        })
                        if(downtimeType === 'downtime'){
                            setTimeout(() => {
                                this.getDowntimes(1)
                            }, 7000);
                            this.$refs.tab1.activate();
                        }
                        if(downtimeType === 'Systemdowntime'){
                            setTimeout(() => {
                                this.getRecurringDowntimes(1)
                            }, 7000);
                            this.$refs.tab4.activate();
                        }
                })
                .catch(error => {
                    this.$notify({
                        type: 'error',
                        title: this.$t('error'),
                    });
                });
        },
        triggerDowntimes: function(){
            if(this.init === true){
                this.getDowntimes(1);
            }
        },

        getDowntimes: function(page){
            this.loading = true;
            let loader = this.$loading.show();
            axios.post('/downtimes/services.json', {
                filter: this.downtimeFilter,
                page: page
            }, {
                params: {}
            }).then(response => {
                    this.serviceDowntimes.downtimes = response.data.serviceDowntimes['all_service_downtimes'] ?? []
                    this.paging = response.data.serviceDowntimes['paging'] ?? {page: 1, pageCount: 1}
                    this.permissions.downtimes = response.data.permissions.downtimes ?? {}
                    this.permissions.systemdowntimes = response.data.permissions.systemdowntimes ?? {}
            })
            .catch(error => {

                this.$notify({
                    type: 'error',
                    title: this.$t('error'),
                });
            })
                .finally(() => {
                    this.loading = false;
                    loader.hide()
                });
        },
        resetDowntimeFilter: function(){
            this.init = false;
            this.getDateTime();
            this.downtimeFilter = {
                filter: {
                    hideExpired: true,
                    isRunning: '',
                    was_cancelled: '',
                    authorName: '',
                    commentData: '',
                    hostname: '',
                    servicename: '',
                    from: new Date(new Date().getTime() - (3600 * 24 * 30 * 1000)).toLocaleString('de-DE', {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit"
                    }).replace(',', ''),
                    to: new Date(new Date().getTime() + (3600 * 24 * 30 * 2000)).toLocaleString('de-DE', {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit"
                    }).replace(',', '')
                }
            };

            this.hideExpiredFilter = true;
            this.isRunningFilter = '';
            this.wasCancelledFilter = false;
            this.commentFilter = null;
            this.userFilter = null;
            this.hostnameFilter = null;
            this.servicenameFilter = null;
            this.getDowntimes(1);
            let scope = this;
            setTimeout(function(){
                scope.init = true;
            }, 1500);
        },
        resetRecurringFilter: function(){
            this.recurringFilter = {
                filter: {
                    hostname: '',
                    servicename: '',
                    author: '',
                    comment: ''
                }
            };
            this.commentRecurringFilter = null;
            this.userRecurringFilter = null;
            this.hostnameRecurringFilter = null;
            this.servicenameRecurringFilter = null;

            this.getRecurringDowntimes(1);
        },
        getDateTime(){
            axios.post('/downtimes/get_dates.json', null, {
                params: {
                    startOffset: -(3600 * 24 * 30),
                    endOffset: (3600 * 24 * 30 * 2)
                }
            })
                .then(response => {
                        let datetime = response.data.datetime[0];
                        this.downtimeFrom = datetime.start_time;
                        this.downtimeTo = datetime.end_time;

                })
                .catch(error => {
                    console.log('Error: ' + error)
                });
        },
        convertDateTime: function(datetimeString){
            let date = new Date(datetimeString);
            let formattedDate = new Intl.DateTimeFormat('de-DE', {
                dateStyle: 'medium',
                timeStyle: 'short'
            }).format(date);
            formattedDate = formattedDate.replace(',', '');
            return formattedDate;
        },
        getRecurringDowntimes(page){
            let loader = this.$loading.show();
            axios.post('/downtimes/services_recurring.json', {
                page: page,
                filter: this.recurringFilter
            }, {
                params: {}
            }).then(response => {
                    this.serviceDowntimes.recurring = response.data.serviceDowntimes['all_service_recurring_downtimes'] ?? [];
                    this.recurringPaging = response.data.serviceDowntimes['paging'] ?? {page: 1, pageCount: 1};
            })
            .catch(error => {
                this.$notify({
                    type: 'error',
                    title: this.$t('error'),
                });
            })
                .finally(() => {
                    loader.hide()
                });
        },
        getSelectedWeekdays: function(weekdays, WeekdayId){
            if(weekdays.includes(WeekdayId)){
                return 'success';
            }
            return 'secondary';
        },
        refreshData: function(){
            this.resetDowntimeFilter();
            this.getDowntimes(this.paging.page);
        }
    },
    mounted(){
        this.getDateTime();
        this.getDowntimes(this.paging.page);
        let scope = this;
        setTimeout(function(){
            scope.init = true;
        }, 1500)

        if(this.$route.params.triggerRefresh){
            setTimeout(function(){
                this.refreshData();
            }.bind(this), 5000);
        }
    },
    watch: {
        hostnameFilter: debounce(function(newValue){
            if(newValue != null){
                this.downtimeFilter.filter.hostname = newValue;
                this.triggerDowntimes();
            }
        }, 700),
        servicenameFilter: debounce(function(newValue){
            if(newValue != null){
                this.downtimeFilter.filter.servicename = newValue;
                this.triggerDowntimes();
            }
        }, 700),
        commentFilter: debounce(function(newValue){
            if(newValue != null){
                this.downtimeFilter.filter.commentData = newValue;
                this.triggerDowntimes();
            }
        }, 700),
        userFilter: debounce(function(newValue){
            if(newValue != null){
                this.downtimeFilter.filter.authorName = newValue;
                this.triggerDowntimes();
            }
        }, 700),
        isRunningFilter: debounce(function(newValue){
            if(newValue != null){
                this.downtimeFilter.filter.isRunning = newValue;
                this.triggerDowntimes();
            }
        }, 700),

        hideExpiredFilter: debounce(function(newValue){
            if(newValue != null){
                this.downtimeFilter.filter.hideExpired = newValue;
                this.triggerDowntimes();
            }
        }, 700),

        wasCancelledFilter: debounce(function(newValue){
            if(newValue != null){
                if(newValue === false){
                    if(this.wasNotCancelledFilter === false){
                        this.downtimeFilter.filter.was_cancelled = '';
                    }else{
                        this.downtimeFilter.filter.was_cancelled = false;
                    }
                }else{
                    if(this.wasNotCancelledFilter === true){
                        this.downtimeFilter.filter.was_cancelled = '';
                    }else{
                        this.downtimeFilter.filter.was_cancelled = true;
                    }
                }
                this.triggerDowntimes();
            }
        }, 700),
        wasNotCancelledFilter: debounce(function(newValue){
            if(newValue != null){
                if(newValue === false){
                    if(this.wasCancelledFilter === false){
                        this.downtimeFilter.filter.was_cancelled = '';
                    }else{
                        this.downtimeFilter.filter.was_cancelled = true;
                    }
                }else{
                    if(this.wasCancelledFilter === false){
                        this.downtimeFilter.filter.was_cancelled = false;
                    }else{
                        this.downtimeFilter.filter.was_cancelled = '';
                    }
                }
                this.triggerDowntimes();
            }
        }, 700),
        downtimeFrom: debounce(function(newValue){
            if(newValue != null){
                if(newValue == ''){
                    this.downtimeFilter.filter.from = new Date(new Date().getTime() - (3600 * 24 * 30 * 1000)).toLocaleString('de-DE').slice(0, 16).replace(',', '');
                }else{
                    this.downtimeFilter.filter.from = this.convertDateTime(newValue);

                }
                this.triggerDowntimes();

            }
        }, 700),
        downtimeTo: debounce(function(newValue){
            if(newValue != null && newValue != ''){
                if(newValue == ''){
                    this.downtimeFilter.filter.to = new Date(new Date().getTime() + (3600 * 24 * 30 * 2000)).toLocaleString('de-DE').slice(0, 16).replace(',', '');
                }else{
                    this.downtimeFilter.filter.to = this.convertDateTime(newValue);
                }
                this.triggerDowntimes();

            }
        }, 700),


        /*
         * Recurring downtimes filter
         */
        hostnameRecurringFilter: debounce(function(newValue){
            if(newValue != null){
                this.recurringFilter.filter.hostname = newValue;
                this.getRecurringDowntimes(1);
            }
        }, 700),
        servicenameRecurringFilter: debounce(function(newValue){
            if(newValue != null){
                this.recurringFilter.filter.servicename = newValue;
                this.getRecurringDowntimes(1);
            }
        }, 700),
        commentRecurringFilter: debounce(function(newValue){
            if(newValue != null){
                this.recurringFilter.filter.comment = newValue;
                this.getRecurringDowntimes(1);
            }
        }, 700),
        userRecurringFilter: debounce(function(newValue){
            if(newValue != null){
                this.recurringFilter.filter.author = newValue;
                this.getRecurringDowntimes(1);
            }
        }, 700),
    },
    computed: {
        actionPermissionsAdd: function(){
            return this.permissions.systemdowntimes.hasOwnProperty('addservicedowntime');

        },
        actionPermissionsDelete: function(){
            return this.permissions.downtimes.hasOwnProperty('delete');

        },
        actionPermissionsSystemDelete: function(){
            return this.permissions.systemdowntimes.hasOwnProperty('delete');

        },
    },
}
</script>

<style scoped>

</style>
