<template>
    <div class="accordion" role="tablist">
        <reset-check-time :uuids="uuids" @masscommand="massCommand">
        </reset-check-time>
        <maintenance :uuids="uuids" @masscommand="massCommand">
        </maintenance>
        <acknowledge :uuids="uuids" @masscommand="massCommand">
        </acknowledge>
        <notifications :uuids="uuids" @masscommand="massCommand">
        </notifications>

    </div>
</template>

<script>
import ResetCheckTime from "./MassServiceResetCheckTime";
import Maintenance from "./MassServiceMaintenance";
import Notifications from "./MassServiceNotifications";
import Acknowledge from "./MassServiceAcknowledge";

export default {
    name: "Actions",
    components: {ResetCheckTime, Maintenance, Notifications, Acknowledge},
    props: [
        'uuids'
    ],
    methods: {
        massCommand(payload){
            let loader = this.$loading.show();
            //console.log(payload)
            axios.post('/services/externalCommands.json',
                payload).then(response => {

                    if(response.data.command.code && response.data.command.code === 403) {
                        this.$notify({
                            type: 'error',
                            title: this.$t('insufficient-rights'),
                            text: this.$t('forbidden-action'),
                            duration: 3000
                        });
                    } else {
                        this.$notify({
                            type: 'success',
                            title: '',
                            text: this.$t('Command successfully sent'),
                            duration: 7000
                        });
                    }

                    setTimeout(() => {
                        this.$emit('refreshindex');
                    }, 7000);
            }).catch(error => {
                this.$notify({
                    type: 'error',
                    title: this.$t('error'),
                });
            })
            .finally(() => {
                loader.hide();
            });
        },
    }
}
</script>

<style scoped>

</style>
