<template>
    <b-button class="w-100 mb-1" variant="outline-secondary" @click="resetCheckTime">
        <i class="fa-solid fa-arrows-rotate"></i>
        {{ $t('action-items.reset-check-time') }}
    </b-button>
</template>

<script>
export default {
    name: "ResetCheckTime",
    props: [
        'uuids',
        'servicestatus'
    ],
    methods: {
        resetCheckTime(){
            let externalCommands = []
            externalCommands.push({
                serviceUuid: this.uuids.serviceuuid,
                hostUuid: this.uuids.hostuuid,
                command: 'SCHEDULE_FORCED_SVC_CHECK'
            });
            this.$emit('command', externalCommands);
        },
    }
}
</script>

<style scoped>

</style>
