<template>
    <div ref="loader">
        <div class="w-100" v-bind:class="mainHeaderBgClasses">
            <b-container class="container-lg">

                <!-- Servicename and refresh button -->
                <b-row class="p-0 pt-2">
                    <b-col class="col-9 text-left min-width-0">
                        <h5 class="text-truncate">
                            <router-link :to="{name: 'servicesIndex', params: {}}"
                                         class="text-decoration-none">
                                <i class="fa-solid fa-chevron-left"></i>
                            </router-link>
                            {{ mergedService.name }}
                        </h5>
                    </b-col>
                    <b-col class="col-3 text-right">
                        <button type="button" class="btn btn-sm" @click="refreshData"
                                v-bind:class="mainHeaderRefreshBtnClasses">
                            <i class="fa-solid fa-arrows-rotate"
                               :class="{'fa-spin': loading}"></i>
                        </button>
                    </b-col>
                </b-row>

                <!-- Not in monitoring text -->
                <b-row class="p-0 pt-2 pb-2" v-if="servicestatus.isInMonitoring === false">
                    <b-col class="col-12">
                        <i class="fa-solid fa-triangle-exclamation mr-1"></i>
                        {{ $t('Not-in-monitoring') }}
                    </b-col>
                </b-row>

                <!-- time information -->
                <b-row class="p-0 pt-2 pb-2" v-if="servicestatus.isInMonitoring">
                    <b-col class="col-12">
                        <i class="fa-solid fa-clock-rotate-left mr-1"></i>
                        {{ $t('state-since') }}:
                        <span v-b-popover.click.blur.bottom="servicestatus.last_state_change_user">
                        {{ servicestatus.last_state_change }}
                    </span>
                    </b-col>
                    <b-col class="col-12">
                        <!-- width: 16 to make all icons the same size -->
                        <i class="fa-solid fa-arrow-turn-up fa-rotate-270 mr-1" style="width:16px;"></i>
                        {{ $t('last-check') }}:
                        <span v-b-popover.click.blur.bottom="servicestatus.lastCheckUser">
                        {{ servicestatus.lastCheck }}
                    </span>
                    </b-col>
                    <b-col class="col-12">
                        <i class="fa-solid fa-arrow-turn-down fa-rotate-270 mr-1" style="width:16px;"></i>
                        {{ $t('next-check') }}:
                        <span
                            v-show="mergedService.active_checks_enabled && host.is_satellite_host === false">
                            <span v-b-popover.click.blur.bottom="servicestatus.nextCheckUser">
                                {{ servicestatus.nextCheck }}
                            </span>
                    </span>
                        <span
                            v-show="!mergedService.active_checks_enabled || host.is_satellite_host === true">
                            {{ $t('n/a') }}
                    </span>
                    </b-col>
                </b-row>

                <!-- icons -->
                <b-row class="p-0 pt-2 pb-2">
                    <b-col class="text-left">
                        <div class="icon-group" role="group">
                            <div class="icon">
                                <priority :priority="mergedService.priority" :as-bar="true"></priority>
                            </div>
                        </div>
                    </b-col>
                    <b-col class="text-right" v-if="servicestatus.isInMonitoring">
                        <div class="icon-group" role="group">
                            <div class="icon">
                                <i v-show="servicestatus.acknowledgement_type === 1 && servicestatus.problemHasBeenAcknowledged === true"
                                   v-b-popover.click.blur.left="$t('Acknowledged')"
                                   class="icon fa-regular fa-user"></i>

                                <i v-show="servicestatus.acknowledgement_type === 2 && servicestatus.problemHasBeenAcknowledged === true"
                                   v-b-popover.click.blur.left="$t('Sticky Acknowledgement')"
                                   class="icon fa fa-user"></i>

                                <i class="icon fa-solid fa-power-off"
                                   v-b-popover.click.blur.left="$t('in-downtime')"
                                   v-show="servicestatus.scheduledDowntimeDepth > 0"></i>

                                <div class="icon icon-stack"
                                     v-b-popover.click.blur.left="$t('notifications-enabled')"
                                     v-show="servicestatus.notifications_enabled">
                                    <i class="fas fa-envelope"></i>
                                    <i class="fas fa-check opacity-100 fa-xs text-success cornered cornered-lr"></i>
                                </div>

                                <div class="icon icon-stack"
                                     v-b-popover.click.blur.left="$t('notifications-disabled')"
                                     v-show="!servicestatus.notifications_enabled">
                                    <i class="fas fa-envelope"></i>
                                    <i class="fas fa-times opacity-100 fa-xs text-danger cornered cornered-lr"></i>
                                </div>
                            </div>
                        </div>
                    </b-col>
                </b-row>

            </b-container>
        </div>

        <b-container class="container-lg mt-0 p-0 pt-4">
            <b-list-group>
                <b-card no-body class="details-tabs card-body p-0">
                    <b-tabs card pills fill active-nav-item-class="active shadow-primary">

                        <b-tab ref="tab1" active>
                            <template #title>
                                <i class="fa-solid fa-info"></i>
                                {{ $t('Details') }}
                            </template>

                            <div v-if="mergedService.disabled" class="alert alert-warning-shadow" role="alert">
                                {{ $t('attention') }}
                                {{ $t('currently-disabled', {obj: 'service'}) }}
                            </div>


                            <div class="alert alert-warning-shadow" role="alert" v-show="hoststatus.currentState > 0">
                                <i class="fa fa-exclamation-triangle"></i>
                                {{ $t('Problem with host detected!') }}
                                <ul>
                                    <li>
                                        <router-link :to="{name: 'hostsDetails', params: {id: host.id}}">
                                            {{ host.hostname }}
                                        </router-link>
                                    </li>
                                </ul>
                            </div>

                            <div v-if="!servicestatus.isInMonitoring"
                                 class="w-100 border-primary text-primary shadow-primary p-2 rounded text-center">
                                {{ $t('This service is currently not in monitoring.') }}
                            </div>

                            <div v-if="hoststatus.isInMonitoring">
                                <!-- Host Downtime -->
                                <b-list-group-item
                                    class="list-group-item-details p-0 pt-1 pb-1 border-none border-bottom"
                                    v-if="hoststatus.scheduledDowntimeDepth > 0">
                                    <div class="mb-3">
                                        <p class="mb-1 overflow-auto">
                                            <i class="fa-solid fa-power-off"></i>
                                            {{ $t('text.maintenance-period', {object: 'Host'}) }}
                                        </p>
                                        <small class="mb-2 overflow-auto"
                                               v-html="$t('text.downtime-set',{owner: hostDowntime.authorName, duration: hostDowntime.durationHuman, start: hostDowntime.scheduledStartTime, end: hostDowntime.scheduledEndTime,  comment: hostDowntime.commentData})">
                                        </small>
                                    </div>
                                </b-list-group-item>

                                <!-- Host Acknowledged -->
                                <b-list-group-item
                                    class="list-group-item-details p-0 pt-1 pb-1 border-none border-bottom"
                                    v-if="hoststatus.problemHasBeenAcknowledged">
                                    <div>
                                        <p class="mb-1 overflow-auto">
                                            <i v-show="!hostAcknowledgement.is_sticky"
                                               class="fa-regular fa-user"
                                               v-b-popover.click.blur="$t('Acknowledged')"></i>
                                            <i v-show="hostAcknowledgement.is_sticky"
                                               class="fa-solid fa-user"
                                               v-b-popover.click.blur="$t('Sticky Acknowledgement')"></i>

                                            {{ $t('text.state-is-acknowledged', {object: 'Host'}) }}
                                            <span v-if="hostAcknowledgement.is_sticky">
                                                ({{ $t('forms.sticky') }})
                                            </span>
                                        </p>
                                        <small class="mb-1 overflow-auto"
                                               v-html="$t('text.acknowledgement-set',{owner: hostAcknowledgement.author_name, time: hostAcknowledgement.entry_time, comment: hostAcknowledgement.commentDataHtml})">
                                        </small>
                                    </div>
                                </b-list-group-item>
                            </div>

                            <div v-if="servicestatus.isInMonitoring">
                                <!-- Service Downtime -->
                                <b-list-group-item
                                    class="list-group-item-details p-0 pt-1 pb-1 border-none border-bottom"
                                    v-if="servicestatus.scheduledDowntimeDepth > 0">
                                    <div class="mb-3">
                                        <p class="mb-1 overflow-auto">
                                            <i class="fa-solid fa-power-off"></i>
                                            {{ $t('text.maintenance-period', {object: 'Service'}) }}
                                        </p>
                                        <small class="mb-2 overflow-auto"
                                               v-html="$t('text.downtime-set',{owner: downtime.authorName, duration: downtime.durationHuman, start: downtime.scheduledStartTime, end: downtime.scheduledEndTime,  comment: downtime.commentData})">
                                        </small>
                                    </div>
                                </b-list-group-item>

                                <!-- Service Acknowledged -->
                                <b-list-group-item
                                    class="list-group-item-details p-0 pt-1 pb-1 border-none border-bottom"
                                    v-if="servicestatus.problemHasBeenAcknowledged">
                                    <div>
                                        <p class="mb-1 overflow-auto">
                                            <i v-show="!acknowledgement.is_sticky"
                                               class="fa-regular fa-user"
                                               v-b-popover.click.blur="$t('Acknowledged')"></i>
                                            <i v-show="acknowledgement.is_sticky"
                                               class="fa-solid fa-user"
                                               v-b-popover.click.blur="$t('Sticky Acknowledgement')"></i>

                                            {{ $t('text.state-is-acknowledged', {object: 'Service'}) }}
                                            <span v-if="acknowledgement.is_sticky">
                                                ({{ $t('forms.sticky') }})
                                            </span>
                                        </p>
                                        <small class="mb-1 overflow-auto"
                                               v-html="$t('text.acknowledgement-set',{owner: acknowledgement.author_name, time: acknowledgement.entry_time, comment: acknowledgement.commentDataHtml})">
                                        </small>
                                    </div>
                                </b-list-group-item>

                                <!-- General state information -->
                                <b-list-group-item
                                    class="list-group-item-details p-0 pt-1 pb-1 border-none border-bottom">
                                    <div class="d-flex w-100 justify-content-between">
                                        <small>
                                            {{ $t('state') }}
                                        </small>
                                        <h5 class="mb-1" v-bind:class="servicestatus.humanState">
                                            {{ $t(servicestatus.humanState) }}
                                        </h5>
                                    </div>

                                    <div class="d-flex w-100 justify-content-between">
                                        <small>
                                            {{ $t('state-type') }}
                                        </small>
                                        <small class="mb-1" v-if="servicestatus.isHardstate">{{ $t('hard-state') }}
                                            ({{ servicestatus.current_check_attempt }}/{{
                                                servicestatus.max_check_attempts
                                            }})
                                        </small>
                                        <small class="mb-1" v-if="!servicestatus.isHardstate">{{ $t('soft-state') }}
                                            ({{ servicestatus.current_check_attempt }}/{{
                                                servicestatus.max_check_attempts
                                            }})
                                        </small>
                                    </div>

                                    <div class="d-flex w-100 justify-content-between"
                                         v-show="servicestatus.currentState > 0">
                                        <small>
                                            {{ $t('last-time') }} <span class="badge badge-success bg-ok">
                                            {{ $t('ok') }}
                                        </span>
                                        </small>
                                        <small>
                                            {{ servicestatus.last_time_ok }}
                                        </small>
                                    </div>
                                    <div class="d-flex w-100 justify-content-between">
                                        <small>
                                            {{ $t('flap-detection-enabled') }}
                                        </small>
                                        <small>
                                <span class="badge badge-success bg-up"
                                      v-if="servicestatus.flap_detection_enabled">
                                    {{ $t('yes') }}
                                </span>
                                            <span class="badge badge-danger bg-down"
                                                  v-if="!servicestatus.flap_detection_enabled">
                                    {{ $t('no') }}
                                </span>
                                        </small>
                                    </div>

                                    <div class="d-flex w-100 justify-content-between">
                                        <small>
                                            {{ $t('check-period') }}
                                        </small>
                                        <small>
                                            {{ checkPeriod.name }}
                                        </small>
                                    </div>
                                    <div class="d-flex w-100 justify-content-between">
                                        <small>
                                            {{ $t('check-interval') }}
                                        </small>
                                        <small>
                                            {{ mergedService.checkIntervalHuman }}
                                        </small>
                                    </div>
                                    <div class="d-flex w-100 justify-content-between">
                                        <small>
                                            {{ $t('retry-interval') }}
                                        </small>
                                        <small>
                                            {{ mergedService.retryIntervalHuman }}
                                        </small>
                                    </div>
                                </b-list-group-item>

                                <!-- Output and Plugin -->
                                <b-list-group-item
                                    class="list-group-item-details p-0 pt-1 pb-1 border-none border-bottom">
                                    <div class="d-flex w-100 justify-content-between">
                                        <small>
                                            {{ $t('Host') }}
                                        </small>
                                        <small>
                                            <router-link :to="{name: 'hostsDetails', params: {id: host.id}}">
                                                {{ host.hostname }}
                                            </router-link>
                                        </small>
                                    </div>
                                    <div class="d-flex w-100 justify-content-between">
                                        <small>
                                            {{ $t('Address') }}
                                        </small>
                                        <small>
                                            {{ host.address }}
                                        </small>
                                    </div>

                                    <div class="d-flex w-100 justify-content-between">
                                        <small>
                                            {{ $t('commandline') }}
                                        </small>
                                    </div>
                                    <div class="d-flex w-100 justify-content-between code-block output-style">
                                        {{ mergedService.serviceCommandLine }}
                                    </div>

                                    <div class="d-flex w-100 justify-content-between">
                                        <small>
                                            {{ $t('output') }}
                                        </small>
                                    </div>
                                    <div class="d-flex w-100 justify-content-between code-block output-style">
                                        {{ servicestatus.output }}
                                    </div>
                                </b-list-group-item>

                                <!-- Notification option -->
                                <b-list-group-item
                                    class="list-group-item-details p-0 pt-1 pb-1 border-none">
                                    <div class="d-flex w-100 justify-content-between">
                                        <small>
                                            {{ $t('notification-period') }}
                                        </small>
                                        <small>
                                            {{ notifyPeriod.name }}
                                        </small>
                                    </div>
                                    <div class="d-flex w-100 justify-content-between">
                                        <small>
                                            {{ $t('notification-interval') }}
                                        </small>
                                        <small>
                                            {{ mergedService.notificationIntervalHuman }}
                                        </small>
                                    </div>
                                    <div class="d-flex w-100 justify-content-between">
                                        <small>
                                            {{ $t('notifications-enabled') }}
                                        </small>
                                        <small>
                                <span class="badge badge-success bg-up"
                                      v-if="hoststatus.notifications_enabled">
                                    {{ $t('yes') }}
                                </span>
                                            <span class="badge badge-danger bg-down"
                                                  v-if="!hoststatus.notifications_enabled">
                                    {{ $t('no') }}
                                </span>
                                        </small>
                                    </div>
                                    <div class="d-flex w-100 justify-content-between">
                                        <small>
                                            {{ $t('notify-on') }}
                                        </small>
                                    </div>
                                    <div class="d-flex w-100 justify-content between">
                                        <small class="ml-auto">
                                            <span class="badge badge-success bg-ok"
                                                  v-if="mergedService.notify_on_recovery">
                                                {{ $t('recover') }}
                                            </span>
                                            <span class="badge badge-warning bg-warning"
                                                  v-if="mergedService.notify_on_warning">
                                                {{ $t('recover') }}
                                            </span>
                                            <span class="badge badge-danger bg-critical"
                                                  v-if="mergedService.notify_on_critical">
                                                    {{ $t('down') }}
                                            </span>
                                            <span class="badge badge-secondary bg-unknown"
                                                  v-if="mergedService.notify_on_unknown">
                                                    {{ $t('unreachable') }}
                                            </span>
                                            <span class="badge badge-primary"
                                                  v-if="mergedService.notify_on_downtime">
                                                    {{ $t('downtime') }}
                                            </span>
                                            <span class="badge badge-primary"
                                                  v-if="mergedService.notify_on_flapping">
                                                    {{ $t('flapping') }}
                                            </span>
                                        </small>
                                    </div>
                                </b-list-group-item>
                            </div>
                        </b-tab>
                        <b-tab ref="tab2" v-if="mergedService.has_graph && servicestatus.isInMonitoring">
                            <template #title>
                                <i class="fa-solid fa-chart-area"></i>
                                {{ $t('Graphs') }}
                            </template>

                            <!-- start service graph -->
                            <div class="w-100">
                                <line-graph ref="linegraph"></line-graph>
                            </div>
                            <!-- end service graph -->
                        </b-tab>

                        <b-tab ref="tab3"
                               v-if="canSubmitExternalCommands && mergedService.allowEdit && servicestatus.isInMonitoring">
                            <template #title>
                                <i class="fa-solid fa-terminal"></i>
                                {{ $t('actions') }}
                            </template>

                            <div class="w-100">
                                <actions ref="actions" :uuids="{hostuuid: host.uuid, serviceuuid: mergedService.uuid}"
                                         :servicestatus="servicestatus"
                                         :downtime="downtime" :acknowledgement="acknowledgement"
                                         @refresh-event="dataActionRefresh()">
                                </actions>
                            </div>
                        </b-tab>

                    </b-tabs>
                </b-card>
            </b-list-group>
        </b-container>


        <!-- OLD CODE -->
        <div class="container" v-show="insufficientRights">
            <b-row>
                <b-col class="text-center font-weight-bold mt-4">
                    <b-alert variant="danger" show>{{ $t('insufficient-rights') }}</b-alert>
                </b-col>
            </b-row>
        </div>

    </div>
</template>

<script>
import LineGraph from './ServiceGraph.vue'
import Actions from './externalcommands/ServiceActions.vue'
import Priority from "../utils/Priority";

export default {
    name: "ServicesDetails",
    components: {Priority, Actions, LineGraph},
    data(){
        return {
            loading: true,
            mainHeaderBgClasses: '',
            mainHeaderRefreshBtnClasses: '',
            mergedService: [],
            canSubmitExternalCommands: false,
            serviceType: [],
            servicestatus: [],
            downtime: [],
            hostDowntime: [],
            hostAcknowledgement: [],
            host: [],
            hoststatus: [],
            notifyPeriod: [],
            checkCommand: [],
            checkPeriod: [],
            acknowledgement: [],
            insufficientRights: false,
            breadcrumps: [
                {
                    text: 'Services',
                    to: {name: 'servicesIndex'}
                },
                {
                    text: 'Service Details',
                    active: true
                }
            ]
        }
    },
    methods: {
        getData(){
            let loader = this.$loading.show();
            this.loading = true;
            axios.post('/services/details.json',
                {},
                {
                    params: {
                        id: this.$route.params.id,
                    }
                })
                .then(response => {
                   // console.log('error400')
                        if(response.data.service_details && response.data.service_details.code === 403){
                            this.insufficientRights = true;
                        }
                        this.mergedService = response.data.service_details.mergedService ?? [];
                        this.notifyPeriod = response.data.service_details.notifyPeriod ?? [];
                        this.canSubmitExternalCommands = response.data.service_details.canSubmitExternalCommands ?? [];
                        this.servicestatus = response.data.service_details.servicestatus ?? [];
                        this.host = response.data.service_details.host ? response.data.service_details.host.Host : [];
                        this.hoststatus = response.data.service_details.hoststatus ?? [];
                        this.serviceType = response.data.service_details.serviceType ?? [];
                        this.checkCommand = response.data.service_details.checkCommand ?? [];
                        this.checkPeriod = response.data.service_details.checkPeriod ?? [];
                        this.acknowledgement = response.data.service_details.acknowledgement ?? [];
                        this.downtime = response.data.service_details.downtime ?? [];
                        this.hostDowntime = response.data.service_details.hostDowntime ?? [];
                        this.hostAcknowledgement = response.data.service_details.hostAcknowledgement ?? [];
                        this.mainHeaderBgClasses = this.getHeaderBgClass();
                        this.mainHeaderRefreshBtnClasses = this.getHeaderRefreshBtnClass();

                        // Give Vue some time to render the template
                        // Resolves: this.$refs.linegraph is undefined
                        if(this.mergedService.has_graph){
                            setTimeout(function(){
                                this.callGraph(this.host.uuid, this.mergedService.uuid, this.mergedService);
                            }.bind(this), 250);
                        }

                })
                .catch(error => {
                    this.$notify({
                        type: 'error',
                        title: this.$t('no-refresh'),
                    });
                })
                .finally(() => {
                    loader.hide();
                    this.loading = false;
                });
        },
        refreshData(){
            this.getData(1);
        },
        getHeaderBgClass(){
            if(!this.servicestatus.isInMonitoring){
                return 'bg-primary shadow-lg-primary text-white';
            }

            switch(this.servicestatus.currentState){
                case 0:
                    return 'bg-ok shadow-lg-ok text-white';

                case 1:
                    return 'bg-warning shadow-lg-warning text-dark';

                case 2:
                    return 'bg-critical shadow-lg-critical text-white';

                default:
                    return 'bg-unknown shadow-lg-unknown text-white';
            }
        },
        getHeaderRefreshBtnClass(){
            if(!this.servicestatus.isInMonitoring){
                return 'btn-primary';
            }

            switch(this.servicestatus.currentState){
                case 0:
                    return 'btn-success';

                case 1:
                    return 'btn-warning';

                case 2:
                    return 'btn-danger';

                default:
                    return 'btn-secundary text-white';
            }
        },
        dataActionRefresh(){
            this.getData();
            this.$refs.tab1.activate();
        },
        callGraph(host, service, merged){
            this.$refs.linegraph.getGraphData(host, service, merged);
        },
    },
    created(){
        //this.getData();
    },
    watch: {
        // Hacky? Otherwise Vue will not reload the data if a state links to itself
        // /services/details/4 links to /services/details/10
        '$route.params.id': {
            handler: function(){
                this.getData();
            },
            //deep: true,
            //immediate: true
        }
    },
    mounted(){
        this.getData();
    },
}
</script>

<style scoped>
</style>
