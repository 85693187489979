<template>
    <div class="h-100 status-line"
         v-bind:class="['bg-'+human_state, 'shadow-'+human_state]">
    </div>
</template>

<script>
export default {
    name: "Servicestatus",
    data(){
        return {}
    },
    props: {
        current_state: {
            default: 2,
            type: Number
        },
        human_state: String
    },
    computed: {},
    mounted(){
        //console.log(this.current_state);
        //console.log(this.human_state);
    },
    methods: {}
}
</script>

<style scoped>

</style>
