<template>
</template>
<script>
export default {
    name: "Root",
    created(){
        this.$router.push({name: 'dashboardsIndex'})
    }
}
</script>

<style scoped>

</style>
