<template>
    <div>
        <div>
            <b-form-group
                :description="$t('forms.notification-disable-description')">
                <label class="typo__label">Select Method</label>
                <multiselect v-model="disableNotificationsTypeSelected"
                             track-by="value"
                             :close-on-select="true"
                             :show-labels="false"
                             label="text"
                             placeholder="Select one"
                             :options="disableNotificationsTypeOptions"
                             :searchable="false"
                             :allow-empty="false">
                </multiselect>
            </b-form-group>
            <b-button variant="success" class="w-100" v-on:click="sendDisableNotificationsCommand">{{ $t('forms.disable-notifications') }}</b-button>
        </div>
    </div>
</template>

<script>
/*
DISABLE_HOST_NOTIFICATIONS
DISABLE_HOST_SVC_NOTIFICATIONS
ENABLE_HOST_NOTIFICATIONS
ENABLE_HOST_SVC_NOTIFICATIONS
 */
import Multiselect from "vue-multiselect";

export default {
    name: "HostNotifications",
    components: {Multiselect},
    data(){
        return {
            disableNotificationsTypeSelected: {
                value: 'DISABLE_HOST_NOTIFICATIONS',
                text: this.$t('hosts.individual')
            },
            disableNotificationsTypeOptions: [
                {
                    value: 'DISABLE_HOST_NOTIFICATIONS',
                    text: this.$t('hosts.individual')
                },
                {
                    value: 'DISABLE_HOST_SVC_NOTIFICATIONS',
                    text: this.$t('hosts.including-services')
                }
            ],
        }
    },
    props: [
        'hostids',
    ],
    methods: {
        sendDisableNotificationsCommand: function(){
            if(this.hostids.length === 0){
                this.$notify({
                    type: 'error',
                    title: 'no hosts selected',
                })
                return
            }

            let externalCommand = []
            for(let host in this.hostids){
                externalCommand.push({
                    command: 'DISABLE_HOST_NOTIFICATIONS',
                    hostUuid: this.hostids[host].hostUuid,
                })
                if(this.disableNotificationsTypeSelected.value === 'DISABLE_HOST_SVC_NOTIFICATIONS'){
                    externalCommand.push({
                        command: 'DISABLE_HOST_SVC_NOTIFICATIONS',
                        hostUuid: this.hostids[host].hostUuid,
                    })
                }
            }

           // externalCommand = JSON.stringify(Object.assign({}, externalCommand));

            this.sendCommand(externalCommand);

        },
        sendCommand: function(externalCommand){
           /* axios.post('/hosts/external_commands.json', null, {
                params: {
                    externalCommand: externalCommand,
                }
            }) */
            axios.post('/hosts/external_commands.json', externalCommand)
                .then(response => {
                        if(response.data.response.code && response.data.response.code === 403) {
                            this.$notify({
                                type: 'error',
                                title: 'insufficient rights',
                                text: 'Action forbidden for this user',
                                duration: 3000
                            });
                        } else {
                            this.$notify({
                                type: 'success',
                                title: '',
                                text: this.$t('Command successfully sent'),
                                duration: 7000
                            });
                        }
                        this.$emit('refresh')
                })
                .catch(error => {
                    this.$notify({
                        type: 'error',
                        title: this.$t('error'),
                    });
                });
        },
    },
}
</script>

<style scoped>

</style>
