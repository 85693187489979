<template>
    <div class="mt-2" ref="loader">
        <b-container class="container-lg">
            <b-row class="p-0">
                <b-col class="text-left">
                    <h5><i class="fa-solid fa-desktop"></i> {{ $t('Hosts') }}</h5>
                </b-col>
                <b-col class="text-right">
                    <button type="button" class="btn btn-light btn-sm" @click="refreshData">
                        <i class="fa-solid fa-arrows-rotate"
                           :class="{'fa-spin': loading}"></i>
                    </button>
                </b-col>
            </b-row>
            <b-list-group>
                <b-list-group-item>
                    <div class="row">
                        <div class="col">
                            <b-button v-b-toggle.filter class="m-0 w-100" variant="primary">
                                <i class="fa-solid fa-filter"></i>
                                {{ $t('Filter') }}
                            </b-button>
                        </div>
                        <div class="col" v-show="actionPermissions">
                            <b-button v-b-toggle.mass class="m-0 w-100"
                                      variant="outline-secondary"
                                      @click="massMode()">
                                <i class="fa-regular fa-square-check"></i>
                                {{ $t('actions') }}
                            </b-button>
                        </div>
                    </div>
                    <b-collapse id="filter">
                        <div class="mt-2">
                            <b-button class="bg-critical w-100" v-on:click="unhandledFilter">
                                {{ $t('filter.unhandled') }}
                            </b-button>
                        </div>

                        <div class="d-flex w-100 mt-2">
                            <b-form-input v-model="hostname" :placeholder="$t('hostname')"></b-form-input>
                        </div>
                        <div class="d-flex w-100 mt-2">
                            <b-form-input v-model="hostaddress" :placeholder="$t('hostaddress')"></b-form-input>
                        </div>
                        <div class="d-flex w-100 mt-2">
                            <multiselect v-model="hoststates"
                                         track-by="value"
                                         :multiple="true"
                                         :close-on-select="true"
                                         :show-labels="false"
                                         label="label"
                                         placeholder="Host status"
                                         :options="hoststateFilterOptions"
                                         :searchable="false"
                                         :allow-empty="true">
                            </multiselect>
                        </div>
                        <div class="mt-2">
                            <b-button
                                class="w-100"
                                variant="outline-danger"
                                v-on:click="allFilter">{{ $t('filter.reset-filter') }}
                            </b-button>
                        </div>
                    </b-collapse>
                </b-list-group-item>
            </b-list-group>
            <b-list-group class="mt-3" v-if="massActions">
                <b-tabs fill>
                    <b-tab ref="tab1">
                        <template #title>
                            <b-icon icon="code-slash"></b-icon>
                            <br>
                            {{ $t('actions') }}
                        </template>
                        <div class="w-100">
                            <actions ref="actions" :hostids="massSelections" @refresh-event="dataActionRefresh()">
                            </actions>
                        </div>
                    </b-tab>
                </b-tabs>
            </b-list-group>

            <!-- start new list -->
            <b-list-group class="mt-3" v-if="massActions">

                <b-list-group-item class="p-0">

                    <b-button-group class="d-flex">
                        <b-button @click="selectAll" variant="primary">
                            <i class="fa-regular fa-square-check"></i>
                            {{ $t('select-all') }}

                            <b-badge pill variant="light" v-show="massSelections.length > 0"> {{
                                    massSelections.length
                                }}
                            </b-badge>

                        </b-button>

                        <b-button @click="deselectAll" variant="secondary">
                            <i class="fa-regular fa-square"></i>
                            {{ $t('deselect-all') }}
                        </b-button>
                    </b-button-group>

                </b-list-group-item>
            </b-list-group>

            <b-list-group
                v-for="host in hostList"
                v-bind:key="host.Host.id"
                class="mt-2">

                <div class="flex-column align-items-start">
                    <div class="list-group-item flex-column align-items-start list-group-item-action pl-0">

                        <div class="" v-show="massActions">
                            <div class="row">
                                <div class="col-4">
                                    <b-form-checkbox
                                        class="ml-2"
                                        switch
                                        size="lg"
                                        v-if="host.Host.allow_edit"
                                        v-model="massSelections"
                                        v-bind:key="host.Host.uuid"
                                        name="checkbox"
                                        :value="{hostUuid: host.Host.uuid, hostId: host.Host.id}"
                                    >
                                    </b-form-checkbox>
                                </div>
                                <div class="col-8 text-right">
                                    <span
                                        class="font-italic"
                                        v-show="host.Host.active_checks_enabled === false || host.Host.is_satellite_host === true">
                                        {{$t('Passive') }}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex flex-row min-h-110">
                            <div class="p-2">
                                <Hoststatus v-bind:current_state="host.Hoststatus.currentState"
                                            v-bind:human_state="host.Hoststatus.humanState"></Hoststatus>
                            </div>
                            <div class="min-width-0">
                                <div class="row p-2">
                                    <div class="col-12 text-truncate h5">
                                        <router-link :to="{name: 'hostsDetails', params: {id: host.Host.id}}">
                                            {{ host.Host.name }}
                                        </router-link>
                                    </div>
                                    <div class="col-12 text-truncate">
                                        <div class="row">
                                            <div class="col-12 text-truncate">
                                                {{ host.Host.address }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 text-right">
                                        <div class="icon-group" role="group">
                                            <div class="icon">
                                                <priority :priority="host.Host.priority"></priority>

                                                <i v-show="host.Hoststatus.acknowledgement_type === 1 && host.Hoststatus.problemHasBeenAcknowledged === true"
                                                   class="icon fa-regular fa-user"></i>

                                                <i v-show="host.Hoststatus.acknowledgement_type === 2 && host.Hoststatus.problemHasBeenAcknowledged === true"
                                                   class="icon fa fa-user"></i>

                                                <i class="icon fa-solid fa-power-off"
                                                   v-show="host.Hoststatus.scheduledDowntimeDepth > 0"></i>

                                                <div class="icon icon-stack"
                                                     v-show="host.Hoststatus.notifications_enabled">
                                                    <i class="fas fa-envelope"></i>
                                                    <i class="fas fa-check opacity-100 fa-xs text-success cornered cornered-lr"></i>
                                                </div>

                                                <div class="icon icon-stack"
                                                     v-show="!host.Hoststatus.notifications_enabled">
                                                    <i class="fas fa-envelope"></i>
                                                    <i class="fas fa-times opacity-100 fa-xs text-danger cornered cornered-lr"></i>
                                                </div>
                                            </div>
                                        </div>

                                        <date-switch v-bind:css_class="host.Hoststatus.humanState"
                                                     v-bind:status="host.Hoststatus"></date-switch>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </b-list-group>
            <!-- end new list -->

            <b-list-group class="mt-3" ref="noMass">
                <div v-if="hostList.length > 0">
                    <div class="d-flex w-100 justify-content-between">
                        <small>{{ $t('page') }} {{ paging.page }}</small>
                        <small>{{ $t('pagesize') }} {{ paging.limit }}</small>
                    </div>
                    <div class="d-flex w-100 justify-content-center mt-4">
                        <sliding-pagination
                            :current="paging.page"
                            :total="paging.pageCount"
                            @page-change="getData">
                        </sliding-pagination>
                    </div>
                </div>
                <b-list-group-item v-if="hostList.length === 0">
                    <h5 class="mb-1">{{ $t('noitems') }}</h5>
                </b-list-group-item>
            </b-list-group>

        </b-container>
    </div>
</template>

<script>
import SlidingPagination from 'vue-sliding-pagination'
import Priority from "../utils/Priority"
import Actions from './externalcommands/MassHostExternalCommands.vue'
import {debounce} from "../../helpers";
import Multiselect from "vue-multiselect";
import Hoststatus from "../utils/Hoststatus";
import DateSwitch from "../utils/DateSwitch";

export default {
    name: "HostsIndex",
    components: {DateSwitch, Hoststatus, SlidingPagination, Priority, Actions, Multiselect},
    props: [
        'filter'
    ],
    data(){
        return {
            loading: true,
            hostList: [],
            refresh: 0,
            listFilter: {
                filter: {
                    id: [],
                    name: "",
                    keywords: [],
                    not_keywords: [],
                    address: "",
                    satellite_id: [],
                    hostdescription: "",
                    output: "",
                    current_state: [],
                    problem_has_been_acknowledged: "",
                    scheduled_downtime_depth: "",
                    notifications_enabled: "",
                    hostpriority: [],
                }
            },
            paging: {
                page: 1,
                pageCount: 1,
            },
            massActions: false,
            massSelections: [],
            hostname: '',
            hostaddress: '',
            hoststates: [],
            hoststateFilterOptions: [
                {
                    label: this.$t('up'),
                    value: 'up'
                },
                {
                    label: this.$t('down'),
                    value: 'down'
                },
                {
                    label: this.$t('unreachable'),
                    value: 'unreachable'
                },
            ],
            permissions: {}
        };
    },
    created(){

        this.resetFilter();
        if(this.getFilter != null){
            this.listFilter = this.getFilter;
        }
        this.getData();
    },
    computed: {
        getFilter: function(){
            return this.filter;
        },
        actionPermissions: function(){
            return this.permissions.hasOwnProperty('externalcommands');
        }
    },
    methods: {
        getData: function(page = 1){
            let loader = this.$loading.show();
            this.loading = true;
            axios.post('/hosts/index.json', null, {
                params: {
                    filter: this.listFilter,
                    page: page
                }
            })
                .then(response => {
                        this.hostList = response.data.return.all_hosts ?? [];
                        this.permissions = response.data.permissions ?? {};
                        this.paging = response.data.return.paging ?? {
                            page: 1,
                            pageCount: 1,
                        };
                    if(this.refresh === 1){
                        this.$notify({
                            type: 'success',
                            title: this.$t('refresh'),
                        })
                    }
                })
                .catch(error => {
                            this.$notify({
                                type: 'error',
                                title: this.$t('error'),
                            });
                })
                .finally(() => {
                    this.refresh = 0
                    loader.hide();
                    this.loading = false;
                } );
        },
        refreshData(){
            this.resetFilter();
            this.refresh = 1;
            this.getData(this.paging.page);
        },
        dataActionRefresh(){
            this.getData(1);
            // Hide checkboxes but do not unpick (if the user want to send to commands)
            this.massActions = false;

        },
        selectAll(){
            this.massSelections = [];
            let selected = [];
            for(const data in this.hostList){
                selected.push({
                    //"{hostUuid: host.Host.uuid, hostId: host.Host.id}"
                    hostUuid: this.hostList[data].Host.uuid,
                    hostId: this.hostList[data].Host.id,
                });
            }
            this.massSelections = selected;
        },
        deselectAll(){
            this.massSelections = [];
        },
        massMode(){
            this.massActions = !this.massActions;
        },
        unhandledFilter: function(){
            this.listFilter.filter.current_state = [
                'down',
                'unreachable'
            ];
            this.listFilter.filter.problem_has_been_acknowledged = false;
            this.listFilter.filter.scheduled_downtime_depth = false;
            this.getData();
        },
        allFilter: function(){
            this.resetFilter();
            this.getData();
        },
        resetFilter: function(){
            this.hoststates = null;
            this.hostname = null;
            this.hostaddress = null;
            this.listFilter = {
                filter: {
                    id: [],
                    name: "",
                    keywords: [],
                    not_keywords: [],
                    address: "",
                    satellite_id: [],
                    hostdescription: "",
                    output: "",
                    current_state: [],
                    problem_has_been_acknowledged: "",
                    scheduled_downtime_depth: "",
                    notifications_enabled: "",
                    hostpriority: []
                }
            };
        }
    },
    watch: {
        hostname: debounce(function(newValue){
            if(newValue != null){
                this.listFilter.filter.name = newValue;
                this.getData();
            }
        }, 700),
        hostaddress: debounce(function(newValue){
            if(newValue != null){
                this.listFilter.filter.address = newValue;
                this.getData();
            }
        }, 700),
        hoststates: function(newValue){
            if(newValue != null){
                this.listFilter.filter.current_state = newValue.map(obj => obj.value);
                this.getData();
            }
        }
    }
}
</script>

<style scoped>

</style>
