import { render, staticRenderFns } from "./MassHostNotificationsEnable.vue?vue&type=template&id=5caf4e31&scoped=true&"
import script from "./MassHostNotificationsEnable.vue?vue&type=script&lang=js&"
export * from "./MassHostNotificationsEnable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5caf4e31",
  null
  
)

export default component.exports