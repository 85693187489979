<template>
    <div>
        <b-button v-b-toggle.collapse-maintenance class="w-100 mb-1" variant="outline-secondary"
                  ref="toggleMaintenance">
            <i class="fa-solid fa-power-off"></i>
            {{ $t('action-items.schedule-maintenance') }}
        </b-button>
        <b-collapse id="collapse-maintenance" class="mt-2 mb-2 w-100">
            <b-list-group-item>
                <b-form>
                    <b-form-group
                        id="input-group-maintenance-text"
                        :label="$t('comment')"
                        label-for="textarea-maintenance">
                        <b-form-textarea v-model="$v.MaintenanceForm.comment.$model"
                                         :class="{ 'is-invalid': $v.MaintenanceForm.$error }"
                                         id="textarea-maintenance"
                                         name="textarea-maintenance"
                                         placeholder="$t('comment').."
                                         rows="3"
                                         max-rows="6"
                        ></b-form-textarea>
                        <div class="invalid-feedback" v-if="!$v.MaintenanceForm.comment.required">Field is required
                        </div>
                    </b-form-group>
                    <div class="row">
                        <div class="col-md-6">
                            <label>{{ $t('start') }}</label>
                            <b-form-input :class="{ 'is-invalid': $v.MaintenanceForm.from.$error }" v-model="$v.MaintenanceForm.from.$model" type="datetime-local"></b-form-input>
                            <div class="invalid-feedback" v-if="!$v.MaintenanceForm.from.required">{{ $t('Field is required') }}</div>
                        </div>
                        <div class="col-md-6">
                            <label>{{ $t('end') }}</label>
                            <b-form-input :class="{ 'is-invalid': $v.MaintenanceForm.to.$error }" v-model="$v.MaintenanceForm.to.$model" type="datetime-local"></b-form-input>
                            <div class="invalid-feedback" v-if="!$v.MaintenanceForm.to.required">{{ $t('Field is required') }}</div>
                        </div>
                    </div>
                    <b-button variant="success" @click="setMaintenance"
                              class="w-100 mt-1 mb-1">
                        {{ $t('forms.send-command') }}
                    </b-button>
                </b-form>
            </b-list-group-item>
        </b-collapse>
    </div>
</template>

<script>
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";

export default {
    name: "Maintenance",
    mixins: [validationMixin],
    props: [
        'uuids'
    ],
    data(){
        return {
            MaintenanceForm: {
                comment: 'in progress',
                from: '',
                to: '',
            },
        }
    },
    validations: {
        MaintenanceForm: {
            comment: {
                required,
            },
            from: {required},
            to: {required}
        }
    },
    methods: {
        getDates(){
            axios.post('/downtimes/get_dates.json')
                .then(response => {
                    if(response.status == 200){
                        let datetime = response.data.datetime[0];
                        this.MaintenanceForm.from = datetime.start_time;
                        this.MaintenanceForm.to = datetime.end_time;
                    }
                });
        },
        setMaintenance(){
            this.$v.MaintenanceForm.$touch();
            if(this.$v.MaintenanceForm.$anyError){
                return;
            }
            if(this.uuids.length > 0){
                let externalCommands = [];
                for(var i = 0; i < this.uuids.length; i++){
                    externalCommands.push({
                        serviceUuid: this.uuids[i].serviceUuid,
                        hostUuid: this.uuids[i].hostUuid,
                        start_time: this.MaintenanceForm.from,
                        end_time: this.MaintenanceForm.to,
                        comment: this.MaintenanceForm.comment,
                        author: this.$store.state.user,
                        fixed: 1,
                        trigger_id: 0,
                        command: 'SCHEDULE_SVC_DOWNTIME',
                    });
                }
                this.$refs.toggleMaintenance.click();
                this.$emit('masscommand', externalCommands);
            }else{
                this.$notify({
                    type: 'error',
                    title: 'no services selected',
                });
            }

        },
    },
    mounted(){
        this.getDates();
    },
}
</script>

<style scoped>

</style>
