<template>
    <div>
        <b-button class="w-100 mb-1"
                  variant="outline-secondary"
                  v-show="!servicestatus.flap_detection_enabled"
                  @click="enableFlap">
            <i class="fa-solid fa-circle-half-stroke"></i>
            {{ $t('action-items.flap-detection-enable') }}
        </b-button>
        <b-button class="w-100 mb-1"
                  variant="outline-secondary"
                  v-show="servicestatus.flap_detection_enabled"
                  @click="disableFlap">
            <i class="fa-solid fa-circle-half-stroke"></i>
            {{ $t('action-items.flap-detection-disable') }}
        </b-button>
    </div>
</template>

<script>
export default {
    name: "Flap",
    props: [
        'uuids',
        'servicestatus'
    ],
    methods: {
        enableFlap(){
            let externalCommands = [];
            externalCommands.push({
                serviceUuid: this.uuids.serviceuuid,
                hostUuid: this.uuids.hostuuid,
                command: 'ENABLE_SVC_FLAP_DETECTION'
            });
            this.$emit('command', externalCommands);
        },
        disableFlap(){
            let externalCommands = [];
            externalCommands.push({
                serviceUuid: this.uuids.serviceuuid,
                hostUuid: this.uuids.hostuuid,
                command: 'DISABLE_SVC_FLAP_DETECTION'
            })
            this.$emit('command', externalCommands);
        },
    }
}
</script>

<style scoped>

</style>
