<template>
    <div class="mt-2" ref="loader">
        <b-container class="container-lg">
            <b-row class="p-0">
                <b-col class="text-left">
                    <h5><i class="fa-solid fa-cogs"></i> {{ $t('Services') }}</h5>
                </b-col>
                <b-col class="text-right">
                    <button type="button" class="btn btn-light btn-sm" @click="refreshData">
                        <i class="fa-solid fa-arrows-rotate"
                           :class="{'fa-spin': loading}"></i>
                    </button>
                </b-col>
            </b-row>
            <b-list-group>
                <b-list-group-item>
                    <div class="row">
                        <div class="col">
                            <b-button v-b-toggle.filter class="m-0 w-100" variant="primary">
                                <i class="fa-solid fa-filter"></i>
                                {{ $t('Filter') }}
                            </b-button>
                        </div>
                        <div class="col" v-show="actionPermissions">
                            <b-button v-b-toggle.mass class="m-0 w-100"
                                      variant="outline-secondary"
                                      @click="massMode()">
                                <i class="fa-regular fa-square-check"></i>
                                {{ $t('actions') }}
                            </b-button>
                        </div>
                    </div>
                    <b-collapse id="filter">
                        <div class="mt-2">
                            <b-button class="bg-critical w-100" @click="unhandledFilter">
                                {{ $t('filter.unhandled') }}
                            </b-button>
                        </div>

                        <div class="mt-2">
                            <multiselect v-model="selectedHost"
                                         id="hostSelection"
                                         label="value"
                                         track-by="key"
                                         placeholder="Select Host"
                                         open-direction="bottom"
                                         :options="hosts"
                                         :multiple="true"
                                         :searchable="true"
                                         :loading="isLoading"
                                         :internal-search="false"
                                         :clear-on-select="true"
                                         :close-on-select="true"
                                         :options-limit="300"
                                         :limit="3"
                                         :limit-text="limitText"
                                         :max-height="600"
                                         :show-no-results="false"
                                         :hide-selected="true"
                                         @search-change="getHosts"
                            >
                            </multiselect>
                        </div>
                        <div class="d-flex w-100 mt-2">
                            <b-form-input v-model="servicenameFilter" :placeholder="$t('servicename')"></b-form-input>
                        </div>
                        <div class="d-flex w-100 mt-2">
                            <multiselect v-model="servicestates"
                                         track-by="value"
                                         :multiple="true"
                                         :close-on-select="true"
                                         :show-labels="false"
                                         label="label"
                                         placeholder="Service status"
                                         :options="servicestateFilterOptions"
                                         :searchable="false"
                                         :allow-empty="true">
                            </multiselect>
                        </div>
                        <div class="mt-2">
                            <b-button
                                class="w-100"
                                variant="outline-danger"
                                v-on:click="allFilter">{{ $t('filter.reset-filter') }}
                            </b-button>
                        </div>
                    </b-collapse>
                </b-list-group-item>
            </b-list-group>
            <b-list-group class="mt-3" v-if="massActions">
                <b-tabs fill>
                    <b-tab ref="tab1">
                        <template #title>
                            <b-icon icon="code-slash"></b-icon>
                            <br>
                            {{ $t('actions') }}
                        </template>
                        <div class="w-100">
                            <actions ref="actions" :uuids="massSelections" @refreshindex="dataActionRefresh()">
                            </actions>
                        </div>
                    </b-tab>
                </b-tabs>
            </b-list-group>

            <!-- start new list -->
            <b-list-group class="mt-3" v-if="massActions">

                <b-list-group-item class="p-0">

                    <b-button-group class="d-flex">
                        <b-button @click="selectAll" variant="primary">
                            <i class="fa-regular fa-square-check"></i>
                            {{ $t('select-all') }}

                            <b-badge pill variant="light" v-show="massSelections.length > 0"> {{
                                    massSelections.length
                                }}
                            </b-badge>

                        </b-button>

                        <b-button @click="deselectAll" variant="secondary">
                            <i class="fa-regular fa-square"></i>
                            {{ $t('deselect-all') }}
                        </b-button>
                    </b-button-group>

                </b-list-group-item>
            </b-list-group>

            <b-list-group
                v-for="service in serviceList"
                v-bind:key="service.Service.id"
                class="mt-2">

                <div class="flex-column align-items-start">
                    <div class="list-group-item flex-column align-items-start list-group-item-action pl-0">

                        <div class="" v-show="massActions">
                            <div class="row">
                                <div class="col-4">
                                    <b-form-checkbox
                                        class="ml-2"
                                        switch
                                        size="lg"
                                        v-if="service.Service.allow_edit"
                                        v-model="massSelections"
                                        v-bind:key="service.Service.uuid"
                                        name="checkbox"
                                        :value="{hostUuid: service.Host.uuid, serviceUuid: service.Service.uuid}"
                                    >
                                    </b-form-checkbox>
                                </div>
                                <div class="col-8 text-right">
                                    <span
                                        class="font-italic"
                                        v-show="service.Service.active_checks_enabled === false || service.Host.is_satellite_host === true">
                                        {{ $t('Passive') }}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex flex-row min-h-110">
                            <div class="p-2">
                                <Servicestatus v-bind:current_state="service.Servicestatus.currentState"
                                               v-bind:human_state="service.Servicestatus.humanState"></Servicestatus>
                            </div>
                            <div class="min-width-0">
                                <div class="row p-2">
                                    <div class="col-12 text-truncate h5">
                                        <router-link :to="{name: 'servicesDetails', params: {id: service.Service.id}}">
                                            {{ service.Service.servicename }}
                                        </router-link>
                                    </div>
                                    <div class="col-12 text-truncate">
                                        <div class="row">
                                            <div class="col-12 text-truncate">
                                                <i class="fa-solid fa-desktop"></i>
                                                {{ service.Host.hostname }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 text-right">
                                        <div class="icon-group" role="group">
                                            <div class="icon">
                                                <priority :priority="service.Service.priority"></priority>

                                                <i v-show="service.Servicestatus.acknowledgement_type === 1 && service.Servicestatus.problemHasBeenAcknowledged === true"
                                                   class="icon fa-regular fa-user"></i>

                                                <i v-show="service.Servicestatus.acknowledgement_type === 2 && service.Servicestatus.problemHasBeenAcknowledged === true"
                                                   class="icon fa fa-user"></i>

                                                <i class="icon fa-solid fa-power-off"
                                                   v-show="service.Servicestatus.scheduledDowntimeDepth > 0"></i>

                                                <div class="icon icon-stack"
                                                     v-show="service.Servicestatus.notifications_enabled">
                                                    <i class="fas fa-envelope"></i>
                                                    <i class="fas fa-check opacity-100 fa-xs text-success cornered cornered-lr"></i>
                                                </div>

                                                <div class="icon icon-stack"
                                                     v-show="!service.Servicestatus.notifications_enabled">
                                                    <i class="fas fa-envelope"></i>
                                                    <i class="fas fa-times opacity-100 fa-xs text-danger cornered cornered-lr"></i>
                                                </div>
                                            </div>
                                        </div>

                                        <date-switch v-bind:css_class="service.Servicestatus.humanState"
                                                     v-bind:status="service.Servicestatus"></date-switch>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </b-list-group>
            <!-- end new list -->

            <b-list-group class="mt-3" ref="noMass">
                <div v-if="serviceList.length > 0">
                    <div class="d-flex w-100 justify-content-between">
                        <small>{{ $t('page') }} {{ paging.page }}</small>
                        <small>{{ $t('pagesize') }} {{ paging.limit }}</small>
                    </div>
                    <div class="d-flex w-100 justify-content-center mt-4">
                        <sliding-pagination
                            :current="paging.page"
                            :total="paging.pageCount"
                            @page-change="getData">
                        </sliding-pagination>
                    </div>
                </div>
                <b-list-group-item v-if="serviceList.length === 0">
                    <h5 class="mb-1">{{ $t('noitems') }}</h5>
                </b-list-group-item>
            </b-list-group>
        </b-container>
    </div>
</template>

<script>
import Actions from './externalcommands/MassServiceActions.vue'
import SlidingPagination from 'vue-sliding-pagination'
import Multiselect from "vue-multiselect"
import Priority from "../utils/Priority";
import {debounce} from "../../helpers";
import Servicestatus from "../utils/Servicestatus";
import DateSwitch from "../utils/DateSwitch";

export default {
    name: "ServicesIndex",
    components: {DateSwitch, Servicestatus, Actions, SlidingPagination, Multiselect, Priority},
    props: [
        'state',
        'filter'
    ],
    data(){
        return {
            loading: true,
            nonSlidingSize: 5,
            url: '/services/index.json',
            serviceList: [],
            refresh: 0,
            listFilter: {
                filter: {
                    id: [],
                    uuid: [],
                    hostsId: [],
                    servicename: '',
                    keywords: [],
                    not_keywords: [],
                    servicedescription: "",
                    output: "",
                    current_state: [],
                    problem_has_been_acknowledged: "",
                    scheduled_downtime_depth: "",
                    active_checks_enabled: "",
                    notifications_enabled: "",
                    servicepriority: []
                }
            },
            massActions: false,
            massActionFilter: [
                'disable Services',
                'Reset check time'
            ],
            massState: '',
            massSelections: [],
            paging: {
                page: 1,
                pageCount: 1,
            },
            selectedHost: [],
            hosts: [],
            isLoading: false,
            servicestates: [],
            servicestateFilterOptions: [
                {
                    label: this.$t('ok'),
                    value: 'ok'
                },
                {
                    label: this.$t('warning'),
                    value: 'warning'
                },
                {
                    label: this.$t('critical'),
                    value: 'critical'
                },
                {
                    label: this.$t('unknown'),
                    value: 'unknown'
                },
            ],
            servicenameFilter: '',
            permissions: {}
        }
    },
    methods: {
        getData(page = 1){
            let loader = this.$loading.show();
            this.loading = true;
            axios.post(this.url,
                {
                    filter: this.listFilter,
                    page: page
                },
                {
                    params: {}
                })
                .then(response => {
                        this.paging = response.data.all_services['paging'] ?? {
                            page: 1,
                            pageCount: 1,
                        };
                        this.serviceList = response.data.all_services['all_services'] ?? [];
                        this.permissions = response.data.permissions ?? {};
                        if(this.refresh === 1){
                            this.$notify({
                                type: 'success',
                                title: this.$t('refresh'),
                            })
                            this.refresh = 0
                        }
                })
                .catch(error => {
                    loader.hide();
                    this.$notify({
                        type: 'error',
                        title: this.$t('error'),
                    });
                })
                .finally(() => {
                    loader.hide();
                    this.loading = false;
                });
        },
        refreshData(){
            this.resetFilter();
            this.refresh = 1;
            this.getData(this.paging.page);
        },
        dataActionRefresh(){
            this.refresh = 1;
            this.getData(this.paging.page);
            // Hide checkboxes but do not unpick (if the user want to send to commands)
            this.massActions = false;
        },
        acknowledged(state, problemHasBeenAcknowledged){
            if(problemHasBeenAcknowledged){
                return true;
            }
            return false;
        },
        filterDowntimes(){
            this.resetFilter();
            this.listFilter.filter.scheduled_downtime_depth = true;
            this.getData();
        },
        allFilter(){
            this.resetFilter();
            this.getData();
        },
        unhandledFilter(){
            this.resetFilter();
            this.listFilter.filter.scheduled_downtime_depth = false;
            this.listFilter.filter.problem_has_been_acknowledged = false;
            this.listFilter.filter.current_state = [
                'warning',
                'critical',
                'unknown'
            ];
            this.getData();
        },
        resetFilter(){
            this.selectedHost = null;
            this.servicenameFilter = null;
            this.servicestates = null;
            this.serviceList = [];
            this.paging.page = 1;

            this.listFilter = {
                filter: {
                    id: [],
                    uuid: [],
                    hostsId: [],
                    servicename: '',
                    keywords: [],
                    not_keywords: [],
                    servicedescription: "",
                    output: "",
                    current_state: [],
                    problem_has_been_acknowledged: "",
                    scheduled_downtime_depth: "",
                    active_checks_enabled: "",
                    notifications_enabled: "",
                    servicepriority: []
                }
            };
        },
        selectAll(){
            this.massSelections = [];
            let selected = [];
            for(const data in this.serviceList){
                selected.push({
                    hostUuid: this.serviceList[data].Host.uuid,
                    serviceUuid: this.serviceList[data].Service.uuid
                });
            }
            this.massSelections = selected;
        },
        deselectAll(){
            this.massSelections = [];
        },
        massMode(){
            // this.resetFilter()
            this.massActions = !this.massActions;
            this.getData(this.paging.page);
        },
        limitText(count){
            return `and ${count} other hosts`;
        },
        getHosts(query){
            this.isLoading = true
            let listFilter = {
                filter: {
                    name: query,
                }
            };
            if(query.length === 0){
                this.isLoading = false;
                return;
            }
            axios.post('/hosts/loadHostsByName.json', null, {
                params: {
                    filter: listFilter
                }
            })
                .then(response => {
                    if(response.status === 200){
                        this.hosts = response.data.hosts.hosts ?? [];
                    }
                    this.res = response.data.error
                    this.isLoading = false;
                })
                .catch(error => {
                    this.res = error;
                });
        },
    },
    created(){
        // this.resetFilter()
        if(this.getFilter != null){
            this.listFilter = this.getFilter;
        }
        this.getData();
    },
    computed: {
        getFilter: function(){
            return this.filter;
        },
        actionPermissions: function(){
            return this.permissions.hasOwnProperty('externalcommands');

        }
    },
    watch: {
        selectedHost: function(newValue){
            if(newValue != null){
                this.listFilter.filter.hostsId = newValue.map(obj => obj.key);
                this.getData();
            }
        },
        servicenameFilter: debounce(function(newValue){
            if(newValue != null){
                this.listFilter.filter.servicename = newValue;
                this.getData();
            }
        }, 700),
        servicestates: function(newValue){
            if(newValue != null){
                this.listFilter.filter.current_state = newValue.map(obj => obj.value);
                this.getData();
            }
        },
    }
}
</script>

<style scoped>

</style>
