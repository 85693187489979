<template>
    <div>
        <div class="accordion" role="tablist">
            <b-button block v-b-toggle.hostScheduleResetCheckTime variant="outline-secondary" class="mb-1">
                <i class="fa-solid fa-arrows-rotate"></i>
                {{ $t('action-items.reset-check-time') }}
            </b-button>
            <b-collapse id="hostScheduleResetCheckTime" accordion="my-accordion" role="tabpanel" class="mt-2 mb-2">
                <b-list-group-item>
                    <host-reset-check-time :hostuuid="getUuid" @refresh="actionRefresh()"></host-reset-check-time>
                </b-list-group-item>
            </b-collapse>
            <b-button block v-b-toggle.hostScheduleMaintenance variant="outline-secondary" class="mb-1"
                      v-if="this.getHostStatus.scheduledDowntimeDepth == 0">
                <i class="fa-solid fa-power-off"></i>
                {{ $t('action-items.schedule-maintenance') }}
            </b-button>
            <b-button block v-b-toggle.hostScheduleMaintenance variant="outline-danger" class="mb-1"
                      v-if="this.getHostStatus.scheduledDowntimeDepth > 0">
                <i class="fa-solid fa-trash"></i>
                {{ $t('action-items.remove-schedule-maintenance') }}
            </b-button>
            <b-collapse id="hostScheduleMaintenance" accordion="my-accordion" role="tabpanel" class="mt-2 mb-2">
                <b-list-group-item>
                    <host-downtime :hostuuid="getUuid" :downtime="getDowntime"
                                   :hoststatus="getHostStatus" @refresh="actionRefresh()"></host-downtime>
                </b-list-group-item>
            </b-collapse>
            <span v-if="getHostStatus.currentState > 0">
                <b-button block v-b-toggle.hostAcknowledge variant="outline-secondary" class="mb-1"
                          v-if="!this.getHostStatus.problemHasBeenAcknowledged">
                    <i class="fa-solid fa-user"></i>
                    {{ $t('action-items.acknowledge-host-status') }}
                </b-button>

                <b-button block v-b-toggle.hostAcknowledge variant="outline-danger" class="mb-1"
                          v-if="this.getHostStatus.problemHasBeenAcknowledged">
                    <i class="fa-solid fa-trash"></i>
                    {{ $t('action-items.remove-acknowledge') }}
                </b-button>
            </span>
            <b-collapse id="hostAcknowledge" accordion="my-accordion" role="tabpanel" class="mt-2 mb-2">
                <b-list-group-item>
                    <host-acknowledge :hostuuid="getUuid" :hostid="hostid" :services="getServices"
                                      :problemHasBeenAcknowledged="getHostStatus.problemHasBeenAcknowledged"
                                      @refresh="actionRefresh()"></host-acknowledge>
                </b-list-group-item>
            </b-collapse>
            <b-button block v-b-toggle.hostEnableFlapDetection variant="outline-secondary" class="mb-1"
                      v-if="!this.getHostStatus.flap_detection_enabled">
                <i class="fa-solid fa-circle-half-stroke"></i>
                {{ $t('action-items.flap-detection-enable') }}
            </b-button>
            <b-button block v-b-toggle.hostEnableFlapDetection variant="outline-secondary" class="mb-1"
                      v-if="this.getHostStatus.flap_detection_enabled">
                <i class="fa-solid fa-circle-half-stroke"></i>
                {{ $t('action-items.flap-detection-disable') }}
            </b-button>
            <b-collapse id="hostEnableFlapDetection" accordion="my-accordion" role="tabpanel" class="mt-2 mb-2">
                <b-list-group-item>
                    <host-flapdetection :hostuuid="getUuid"
                                        :flapDetectionEnabled="getHostStatus.flap_detection_enabled"
                                        @refresh="actionRefresh()"></host-flapdetection>
                </b-list-group-item>
            </b-collapse>
            <b-button block v-b-toggle.hostEnableNotifications variant="outline-secondary" class="mb-1"
                      v-if="this.getHostStatus.notifications_enabled">
                <i class="fa-solid fa-envelope"></i>
                {{ $t('action-items.notifications-disable') }}
            </b-button>

            <b-button block v-b-toggle.hostEnableNotifications variant="outline-secondary" class="mb-1"
                      v-if="!this.getHostStatus.notifications_enabled">
                <i class="fa-solid fa-envelope"></i>
                {{ $t('action-items.notifications-enable') }}
            </b-button>
            <b-collapse id="hostEnableNotifications" accordion="my-accordion" role="tabpanel" class="mt-2 mb-2">
                <b-list-group-item>
                    <host-notifications :hostuuid="getUuid"
                                        :notificationsEnabled="getHostStatus.notifications_enabled"
                                        @refresh="actionRefresh()"></host-notifications>

                </b-list-group-item>
            </b-collapse>
        </div>
    </div>
</template>

<script>
import HostAcknowledge from "./HostAcknowledge";
import HostDowntime from "./HostDowntime";
import HostResetCheckTime from "./HostResetCheckTime";
import HostFlapdetection from "./HostFlapdetection";
import HostNotifications from "./HostNotifications";

export default {
    name: "HostExternalCommands",
    components: {
        HostNotifications,
        HostFlapdetection,
        HostResetCheckTime, HostDowntime, HostAcknowledge
    },
    props: [
        'hostuuid',
        'hostid',
        'current_state',
        'status',
        'services',
        'downtime'
    ],
    computed: {
        getUuid: function(){
            return this.hostuuid;
        },
        getHostStatus: function(){
            return this.status;
        },
        getServices: function(){
            return this.services;
        },
        getDowntime: function(){
            return this.downtime;
        }
    },
    methods: {
        actionRefresh(){
            setTimeout(() => {
                this.$emit('refresh-event')
            }, 7000);
        }
    }
}
</script>

<style scoped>

</style>
