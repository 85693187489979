<template>
    <div class="container">
        <h5>{{ $t('forms.create-new-downtime', {obj: 'Service'}) }}</h5>

        <b-form-group>
            <label class="typo__label" for="serviceSelection">{{ $t('select') }} {{ $t('Services') }}</label>
            <span :class="{ 'invalid is-invalid': this.validationErrors.object_id }">

                <multiselect v-model="selectedServices"
                             id="serviceSelection"
                             group-values="services"
                             group-label="hostname"
                             :group-select="true"
                             label="name"
                             track-by="id"
                             placeholder="Type to search"
                             open-direction="bottom"
                             :options="services"
                             :multiple="true"
                             :searchable="true"
                             :loading="isLoading"
                             :internal-search="false"
                             :clear-on-select="false"
                             :close-on-select="false"
                             :options-limit="300"
                             :limit="3"
                             :limit-text="limitText"
                             :max-height="600"
                             :show-no-results="false"
                             :hide-selected="true"
                             @search-change="getServices"
                >
                    <template slot="clear" slot-scope="props">
                        <div class="multiselect__clear" v-if="selectedServices.length"
                             @mousedown.prevent.stop="clearAll(props.search)"></div>
                    </template>
                    <span slot="noResult">{{ $t('no-elements') }}</span>
                </multiselect>
            </span>
            <div class="invalid-feedback" v-for="error in validationErrors.object_id">{{ error }}</div>

        </b-form-group>

        <b-form-group
            :label="$t('comment')"
            label-for="downtimeComment">
            <b-form-input id="downtimeComment" :class="{ 'is-invalid': this.validationErrors.comment }"
                          v-model="downtimeComment" placeholder="Commment"></b-form-input>

            <div class="invalid-feedback" v-for="error in validationErrors.comment">{{ error }}</div>
        </b-form-group>

        <b-form-group
            label-for="recurring">
            <b-form-checkbox
                id="recurring"
                v-model="isRecurringDowntime"
                name="recurringDowntimeCheckbox"
            >
                {{ $t('forms.recurring-downtime') }}

            </b-form-checkbox>
        </b-form-group>

        <div v-if="!isRecurringDowntime">

            <b-form-group>
                <label for="downtimeStart">Start</label>
                <b-form-input
                    :class="{ 'is-invalid': this.validationErrors.from_date ||  this.validationErrors.from_time}"
                    v-model="downtimeStartTime" type="datetime-local"></b-form-input>
                <div class="invalid-feedback" v-for="error in validationErrors.from_date">{{ error }}</div>
                <div class="invalid-feedback" v-for="error in validationErrors.from_time">{{ error }}</div>
            </b-form-group>
            <b-form-group>
                <label for="downtimeStart">{{ $t('end') }}</label>
                <b-form-input :class="{ 'is-invalid': this.validationErrors.to_date ||  this.validationErrors.to_time }"
                              v-model="downtimeEndTime" type="datetime-local"></b-form-input>
                <div class="invalid-feedback" v-for="error in validationErrors.to_date">{{ error }}</div>
                <div class="invalid-feedback" v-for="error in validationErrors.to_time">{{ error }}</div>
            </b-form-group>
        </div>
        <div v-if="isRecurringDowntime">
            <b-form-group
                label="Start"
                label-for="recurringDowntimeStartTimeField">
                <b-form-input :class="{ 'is-invalid': this.validationErrors.from_time}"
                              v-model="recurringDowntimeStartTime" id="recurringDowntimeStartTimeField"
                              type="time"></b-form-input>
                <div class="invalid-feedback" v-for="error in validationErrors.from_time">{{ error }}</div>
            </b-form-group>
            <b-form-group :label="$t('forms.duration')" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="downtimeDuration"
                    v-model="selectedDuration"
                    :options="downtimeQuickSelectOptions"
                    :aria-describedby="ariaDescribedby"
                    name="downtimeDurationGroup"
                    button-variant="outline-secondary"
                    class="flex-wrap"
                    buttons
                ></b-form-radio-group>
            </b-form-group>
            <b-form-group
                label-for="downtimeDurationTextField">
                <b-form-input id="downtimeDurationTextField" :class="{ 'is-invalid': this.validationErrors.duration}"
                              v-model="selectedDuration"
                              placeholder="Duration in minutes"></b-form-input>
                <div class="invalid-feedback" v-for="error in validationErrors.duration">{{ error }}</div>
                <small>{{ selectedDuration }} {{ $t('forms.minutes') }}</small>
            </b-form-group>


            <b-form-group>
                <label class="typo__label">{{ $t('forms.weekdays') }}</label>
                <span :class="{ 'invalid is-invalid': this.validationErrors.weekdays }">
                    <multiselect v-model="selectedWeekdays" :options="weekdays"
                                 :searchable="false" :close-on-select="false"
                                 :show-labels="false" :multiple="true" placeholder="Pick a value" label="value"
                                 track-by="key"
                    ></multiselect>
                </span>
                <div class="invalid-feedback" v-for="error in validationErrors.weekdays">{{ error }}</div>
            </b-form-group>
            <b-form-group
                :label="$t('forms.monthdays')"
                label-for="downtimeDaysOfMonthField">
                <b-form-input :class="{ 'is-invalid': this.validationErrors.day_of_month}" id="downtimeDaysOfMonthField"
                              v-model="daysOfMonth"
                              placeholder="1,2,3,4,5 or <blank>"></b-form-input>
                <div class="invalid-feedback" v-for="error in validationErrors.day_of_month">{{ error }}</div>
            </b-form-group>
        </div>
        <div class="d-flex w-100 justify-content-between">
            <b-button variant="danger" v-on:click="abort">{{ $t('dismiss') }}</b-button>
            <b-button variant="success" v-on:click="createDowntime">{{ $t('create-downtime') }}</b-button>
        </div>
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
    name: "DowntimesServicesAdd",
    components: {Multiselect},
    data(){
        return {
            validationErrors: {},
            selectedServices: [],
            object_id: [],
            services: [],
            isLoading: false,
            downtimeComment: 'in progress',
            downtimeStartTime: '',
            downtimeEndTime: '',
            selectedDuration: 15,
            selectedWeekdays: [],
            selectedWeekdayIds: [],
            daysOfMonth: '',
            isRecurringDowntime: 0,
            recurringDowntimeStartTime: '',
            downtimeQuickSelectOptions: [
                {
                    text: '1m',
                    value: 1
                },
                {
                    text: '2m',
                    value: 2
                },
                {
                    text: '5m',
                    value: 5
                },
                {
                    text: '10m',
                    value: 10
                },
                {
                    text: '15m',
                    value: 15
                },
                {
                    text: '30m',
                    value: 30
                },
                {
                    text: '1h',
                    value: 60
                },
                {
                    text: '2h',
                    value: 60 * 2
                },
                {
                    text: '4h',
                    value: 60 * 4
                },
                {
                    text: '8h',
                    value: 60 * 8
                },

            ],
            weekdays: [
                {
                    key: 1,
                    value: this.$t('days.monday')
                },
                {
                    key: 2,
                    value: this.$t('days.tuesday')
                },
                {
                    key: 3,
                    value: this.$t('days.wednesday')
                },
                {
                    key: 4,
                    value: this.$t('days.thursday')
                },
                {
                    key: 5,
                    value: this.$t('days.friday')
                },
                {
                    key: 6,
                    value: this.$t('days.saturday')
                },
                {
                    key: 7,
                    value: this.$t('days.sunday')
                },
            ],
        }
    },
    methods: {
        limitText(count){
            return `and ${count} other services`
        },
        getServices(query){
            this.isLoading = true
            let listFilter = {
                filter: {
                    servicename: query
                }
            };

            if(query.length == 0){
                this.isLoading = false;
                return;
            }
            axios.post('/services/loadServicesByName.json', null, {
                params: {
                    filter: listFilter
                }
            })
                .then(response => {
                    this.services = response.data.services;
                    this.isLoading = false;
                })
                .catch(error => {
                    this.isLoading = false;
                    console.log('Error: ' + error)
                });
        },
        createDowntime: function(){
            let serviceDowntime = {
                Systemdowntime: {
                    is_recurring: +this.isRecurringDowntime,
                    weekdays: this.selectedWeekdayIds,
                    day_of_month: this.daysOfMonth,
                    from_date: this.splitDateTime(this.downtimeStartTime).date,
                    from_time: this.splitDateTime(this.downtimeStartTime).time,
                    to_date: this.splitDateTime(this.downtimeEndTime).date,
                    to_time: this.splitDateTime(this.downtimeEndTime).time,
                    duration: this.getDurationInMinutes(this.downtimeStartTime, this.downtimeEndTime),
                    downtimetype: 'service',
                    downtimetype_id: this.downtimetypeId, // host or host and services
                    objecttype_id: 2048,     //OBJECT_SERVICE
                    object_id: this.object_id,          //HostIds
                    comment: this.downtimeComment,
                    is_recursive: 0 // only for containerdowntimes
                }
            };

            if(this.isRecurringDowntime){
                serviceDowntime.Systemdowntime.from_date = '';
                serviceDowntime.Systemdowntime.from_time = this.recurringDowntimeStartTime;
                serviceDowntime.Systemdowntime.to_date = '';
                serviceDowntime.Systemdowntime.to_time = '';
                serviceDowntime.Systemdowntime.duration = this.selectedDuration;
            }

            axios.post('/downtimes/services_add.json', {downtime: serviceDowntime})
                .then(response => {
                       // console.log(response)
                        this.$notify({
                            type: 'success',
                            title: this.$t('Command successfully sent'),
                            text: this.$t('refresh5'),
                            duration: 5000
                        });
                        this.validationErrors = {};

                        this.$router.push({
                            name: 'downtimesService',
                            params: {
                                triggerRefresh: true
                            }
                        });
                })
                .catch(error => {
                    this.$notify({
                        type: 'error',
                        title: this.$t('error'),
                    });
                });
        },
        getTime: function(){
            axios.post('/downtimes/get_time.json', null, {})
                .then(response => {
                        this.recurringDowntimeStartTime = response.data.time;

                })
                .catch(error => {
                    console.log('Error: ' + error)
                });
        },
        getDateTime(){
            axios.post('/downtimes/get_dates.json', null, {})
                .then(response => {
                        let datetime = response.data.datetime[0];
                        this.downtimeStartTime = datetime.start_time;
                        this.downtimeEndTime = datetime.end_time;
                })
                .catch(error => {
                    console.log('Error: ' + error)
                });
        },
        splitDateTime: function(datetimeString){
            let fragment = datetimeString.split('T');
            return {
                date: fragment[0],
                time: fragment[1]
            };
        },
        getDurationInMinutes: function(startDate, endDate){
            //microseconds to minutes
            let diff = Math.abs(new Date(endDate) - new Date(startDate));
            let minutes = Math.floor((diff / 1000) / 60);
            return minutes;
        },
        abort(){
            this.$router.push({name: 'downtimesService'})
        }
    },
    mounted(){
        this.getTime();
        this.getDateTime();
    },
    watch: {
        selectedServices(newValues){
            //get the service ids from the selectbox
            this.object_id = newValues.map(obj => obj.id);
        },
        selectedWeekdays(values){
            this.selectedWeekdayIds = values.map(obj => obj.key);
        }
    }
}
</script>

<style scoped>

</style>
