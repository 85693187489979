<template>
    <div>
        <b-button v-b-toggle.collapse-custom class="w-100 mb-1"
                  variant="outline-secondary"
                  ref="toggleCustomNotification">
            <i class="fa-solid fa-envelope-open"></i>
            {{ $t('action-items.custom-notification') }}
        </b-button>
        <b-collapse id="collapse-custom" class="mt-2 mb-2 w-100">
            <b-list-group-item>
                <b-form>
                    <b-form-group
                        id="input-group-1-custom-notification"
                        :label="$t('forms.message')"
                        label-for="textarea-custom-notification">
                        <b-form-textarea v-model="$v.notificationForm.comment.$model"
                                         :class="{ 'is-invalid': $v.notificationForm.$error }"
                                         id="textarea-custom-notification"
                                         placeholder="Test Notification.."
                                         rows="3"
                                         max-rows="6"
                        ></b-form-textarea>
                        <div class="invalid-feedback" v-if="!$v.notificationForm.comment.required">{{ $t('Field is required') }}
                        </div>
                    </b-form-group>
                    <b-form-group id="input-group">
                        <b-form-checkbox-group
                            v-model="notificationForm.checked"
                            id="checkboxes-custom-notification" stacked>
                            <b-form-checkbox value='broadcast'>{{ $t('forms.broadcast') }} <br><small>{{ $t('forms.broadcast-description') }}</small></b-form-checkbox>
                            <b-form-checkbox value='force'>{{ $t('forms.force') }} <br><small>{{ $t('forms.force-description') }}</small></b-form-checkbox>
                        </b-form-checkbox-group>
                    </b-form-group>
                    <b-button variant="success" @click="submitCustomNotification"
                              class="w-100">{{ $t('forms.send-command') }}
                    </b-button>
                </b-form>
            </b-list-group-item>
        </b-collapse>
    </div>
</template>

<script>
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";
export default {
    name: "CustomNotification",
    mixins: [validationMixin],
    props: [
        'uuids',
        'servicestatus'
    ],
    data(){
        return {
            notificationForm: {
                comment: 'Test notification',
                checked: []
            },
        }
    },
    validations: {
        notificationForm: {
            comment: {
                required,
            }
        }
    },
    methods: {
        submitCustomNotification(){
            this.$v.notificationForm.$touch();
            if(this.$v.notificationForm.$anyError){
                return;
            }
            let notification_type = 0;
            if(this.notificationForm.checked.length > 0){
                if(this.notificationForm.checked.length === 1){
                    if(this.notificationForm.checked.includes('broadcast')){
                        notification_type = 1;
                    }
                    if(this.notificationForm.checked.includes('force')){
                        notification_type = 2;
                    }
                }
                if(this.notificationForm.checked.length === 2){
                    notification_type = 3;
                }
            }else{
                notification_type = 0;
            }
            let externalCommands = []
            externalCommands.push({
                serviceUuid: this.uuids.serviceuuid,
                hostUuid: this.uuids.hostuuid,
                options: notification_type,
                author: this.$store.state.user,
                comment: this.notificationForm.comment,
                command: 'SEND_CUSTOM_SVC_NOTIFICATION'
            });

            this.$refs.toggleCustomNotification.click();
            this.$emit('command', externalCommands);
        },
    }
}
</script>

<style scoped>

</style>
