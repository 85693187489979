export function debounce(fn, delay){
    var timer = null;
    return function(){
        clearTimeout(timer);
        var args = arguments;
        var that = this;
        timer = setTimeout(function(){
            fn.apply(that, args);
        }, delay);
    };
}
