<template>
    <!-- nav -->
    <div>
        <b-navbar toggleable="lg" type="dark" variant="dark" sticky>
            <b-navbar-brand link :to="{name: 'dashboardsIndex'}">
                <img :src="headerLogo" class="d-inline-block align-top" style="width:30px;height:30px;"
                     alt="openITCOCKPITmobile" v-b-tooltip.hover :title="version">
                <span class="d-inline-block d-lg-none d-xl-inline-block">
                    <!-- hide system name on rotated iPad and rotated 1080p screen -->
                    {{ systemName }}
                </span>
            </b-navbar-brand>
            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
            <b-collapse id="nav-collapse" v-model="showCollapse" is-nav>
                <b-navbar-nav class="mr-auto">
                    <b-nav-item class="text-center d-lg-none d-xl-block">
                        {{ $t('welcome') }} {{ this.$store.state.user }}
                    </b-nav-item>
                    <li class="nav-item" v-for="action in actions">
                        <router-link class="nav-link" :to="{ name: action.routeName, params: {state: 'index'}}">
                            <i v-bind:class="action.icon"></i>
                            {{ $t(action.title) }}
                        </router-link>
                    </li>
                </b-navbar-nav>

                <b-navbar-nav>
                    <li class="nav-item pt-1 pb-1 pl-lg-2 pt-lg-0 pb-lg-0">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon1">
                                    <i class="fa-solid fa-language"></i>
                                </span>
                            </div>
                            <b-form-select
                                v-model="$i18n.locale" :options="langs" class="form-control navbar-dark-input"
                                @change="changeLang()"></b-form-select>
                        </div>
                    </li>

                    <li class="nav-item pt-1 pb-1 pl-lg-2 pr-lg-2 pt-lg-0 pb-lg-0">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon1">
                                    <i class="fa-regular fa-moon" style="width:20px;"></i> <!-- same icon size -->
                                </span>
                            </div>
                            <b-form-checkbox v-model="darkTheme" switch size="lg" class="form-control navbar-dark-input"
                                             style="padding-left: 3.75rem;"/>
                        </div>
                    </li>
                </b-navbar-nav>

                <b-navbar-nav>
                    <b-button class="nav-item btn-secondary" v-show="loginState" @click="logout">
                        <i class="fa-solid fa-right-from-bracket"></i>
                        {{ $t('Logout') }}
                    </b-button>
                </b-navbar-nav>

            </b-collapse>
        </b-navbar>
        <!-- end nav -->
        <!-- content! -->
        <div id="content" class="container-fluid no-padding">
            <router-view/>
            <!--<notifications position="top center" width="50%"/>-->

        </div>
        <!-- end content -->
    </div>
</template>

<script>
export default {
    data(){
        return {
            actions: [],
            loginState: false,
            darkTheme: false,
            componentKey: false,
            langs: ['de', 'en'],
            headerLogo: '',
            systemName: '',
            version: '',
            showCollapse: false
        }
    },
    methods: {
        logout(){
            this.$store.commit('setState', false);
            localStorage.removeItem('status');


            axios.get('/users/logout')
                .then(response => {
                    if(response.data.type == 'success'){
                        this.$notify({
                            type: 'success',
                            text: response.data.message,
                            duration: 5000
                        });
                        this.getLogin();
                    }else{
                        this.$notify({
                            type: 'error',
                            text: response.data.message,
                            duration: 5000
                        });
                    }

                })
                .catch(error => {
                    console.log('Error: ' + error);
                });
        },
        getLogin(){
            this.loginState = false
            this.$store.commit('setState', false);
            localStorage.removeItem('status');

            // localStorage.clear()
            // this.$store.replaceState({})
            this.showCollapse = false;
            this.$router.push({name: 'login'});
        },
        changeLang(){
            localStorage.setItem('lang', this.$i18n.locale)
            this.showCollapse = false
        },
    },
    created(){
        if(this.$root.theme === 'dark'){
            this.darkTheme = true
        }
        this.loginState = this.$store.state.status === 'true';   // "cast" to bool

        // User is not logged in - no actions
        this.actions = [];

        if(this.loginState === true){
            // Query server to get all alowed actions of this user
            axios.get('/routes/getSidebar.json')
                .then(response => {
                    this.actions = response.data ?? [];
                })
                .catch(error => {
                    console.log('Error: ' + error);
                });
        }
    },
    mounted(){
        this.$root.$on('login-event', () => {
            this.loginState = true;
        });

        axios.post('/users/getSystemConfig.json')
            .then(response => {
                this.headerLogo = response.data.headerLogo;
                this.systemName = response.data.systemName;
                this.version = 'Mobile Version ' + response.data.mobileVersion ?? '';
            })
            .catch(error => {
                console.log('Could not fetch logo');
            });
    },
    watch: {
        loginState(newValue){
            // User is not logged in - no actions
            this.actions = [];

            if(this.loginState === true){
                // Query server to get all alowed actions of this user
                axios.get('/routes/getSidebar.json')
                    .then(response => {
                        this.actions = response.data ?? [];
                    })
                    .catch(error => {
                        console.log('Error: ' + error);
                    });
            }
        },
        darkTheme(newValue){
            this.showCollapse = false
            if(newValue === true){
                this.$root.theme = 'dark'
            }else{
                this.$root.theme = 'light'
            }
        }
    }
}
</script>
