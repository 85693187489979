<template>
    <div>
        <b-button class="w-100 mb-1"
                  variant="outline-secondary"
                  v-show="servicestatus.notifications_enabled"
                  @click="disableNotifications">
            <i class="fa-solid fa-envelope"></i>
            {{ $t('action-items.notifications-disable') }}
        </b-button>
        <b-button class="w-100 mb-1"
                  variant="outline-secondary"
                  v-show="!servicestatus.notifications_enabled"
                  @click="enableNotifications">
            <i class="fa-solid fa-envelope"></i>
            {{ $t('action-items.notifications-enable') }}
        </b-button>
    </div>
</template>

<script>
export default {
    name: "Notifications",
    props: [
        'uuids',
        'servicestatus'
    ],
    methods: {
        enableNotifications(){
            let externalCommands = [];
            externalCommands.push({
                serviceUuid: this.uuids.serviceuuid,
                hostUuid: this.uuids.hostuuid,
                command: 'ENABLE_SVC_NOTIFICATIONS'
            });
            this.$emit('command', externalCommands);
        },
        disableNotifications(){
            let externalCommands = [];
            externalCommands.push({
                serviceUuid: this.uuids.serviceuuid,
                hostUuid: this.uuids.hostuuid,
                command: 'DISABLE_SVC_NOTIFICATIONS'
            });
            this.$emit('command', externalCommands);
        },
    }
}
</script>

<style scoped>

</style>
