<template>
    <div class="mt-2" ref="loader">
        <div class="container-fluid border">
            <div class="row">
                <div class="col-md-4">
                    <div class="mt-2">
                        <label>{{ $t('datasource') }}: </label>
                    </div>
                    <div>
                        <select v-model="currentDataSource" class="w-100" @change="renderSelected()">
                            <option v-for="source in dataSources" v-bind:value="source">
                                {{ source }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="mt-2">
                        <label>{{ $t('timerange') }}: </label>
                    </div>
                    <div>
                        <select v-model="currentSelectedTimerange" class="w-100" @change="renderSelected()">
                            <option v-for="(timerangeName, timerange) in availableTimeranges" v-bind:value="timerange"
                            >
                                {{ timerangeName }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="mt-2">
                        <label>{{ $t('Aggregation') }}: </label>
                    </div>
                    <div>
                        <select v-model="currentAggregation" class="w-100" @change="renderSelected()">
                            <option v-for="(aggregationName, aggregation) in availableAggregations"
                                    v-bind:value="aggregation">
                                {{ aggregationName }}
                            </option>
                        </select>
                    </div>
                </div>
                <div style="position: relative;height:300px;" class="w-100">
                    <canvas id="canvas" ref="canvas"></canvas>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import gradient from 'chartjs-plugin-gradient'
import 'chartjs-adapter-luxon';
import { DateTime } from "luxon";
import {
    Chart,
    LineElement,
    PointElement,
    LineController,
    ScatterController,
    CategoryScale,
    LinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle
} from 'chart.js'

Chart.register(LineElement, PointElement, LineController, ScatterController, CategoryScale, LinearScale, TimeScale, TimeSeriesScale, Decimation, Filler, Legend, Title, Tooltip, SubTitle, gradient)

export default {
    name: "LineGraph",
    data(){
        return {
            hostuuid: '',
            serviceuuid: '',
            perfdata: {},
            timezone: [],
            mergedService: [],
            dataSources: [],
            currentDataSource: '',
            serverTimeDateObject: {},
            currentGraphUnit: null,
            // loaded: false,
            datasetData: [],
            options: {
                /* tooltips: {
                     mode: 'nearest'
                 }, */
                scales: {
                    x: {},
                    y: {}
                }
            },
            graphStart: 0,
            graphEnd: 0,
            graphRenderEnd: 0,
            colors: {
                defaultFillColor: 'rgb(66, 133, 244, 0.2)',
                defaultBorderColor: 'rgba(50, 116, 217, 1)',
            },
            currentSelectedTimerange: 3,
            availableTimeranges: {
                1: '1 hour',
                3: '3 hours',
                8: '8 hours',
                24: '1 day',
            },
            currentAggregation: 'avg',
            availableAggregations: {
                avg: 'Average',
                min: 'Minimum',
                max: 'Maximum'
            }
        }
    },
    methods: {

        getData(){
            //console.log(DateTime);
            let loader = this.$loading.show()
            axios.post('/downtimes/get_timezone_object.json').then(response => {
                    this.timezone = response.data.timezone.timezone;
                    this.serverTimeDateObject = new Date(this.timezone.server_time_iso);

                    let graphEnd = Math.floor((this.serverTimeDateObject.getTime()) / 1000);
                    let graphStart = graphEnd - (3600 * this.currentSelectedTimerange);

                    this.graphEnd = graphEnd;
                    this.graphStart = graphStart;
                    this.graphRenderEnd = graphEnd;
                    this.getPerfData(loader);

            }).catch(error => {
               /* this.$notify({
                    type: 'error',
                    title: 'Error',
                    //text: error.response.data.message
                }); */
                console.log('Error: ' + error);
            })
                .finally(() => {
                    loader.hide();
                    this.loading = false;
                });
        },

        getPerfData(loader){
            let payload = {
                service_uuid: this.serviceuuid,
                host_uuid: this.hostuuid,
                start: this.graphStart,
                end: this.graphEnd,
                jsTimestamp: 1,
                //hours: 4,
                gauge: this.currentDataSource,
                aggregation: this.currentAggregation
            };
            axios.post('/services/perfdata.json', payload)
                .then(response => {
                    this.shiftTimestamps(response.data.perfdata.performance);
                    this.loaded = true;
                    this.fillData();
                    loader.hide();
                }).catch(error => {
                loader.hide();
                this.$notify({
                    type: 'error',
                    title: 'Error',
                    text: this.$t('error')
                });
            })
                .finally(() => {
                    loader.hide();
                });
        },

        fillData(){
            let datasetData = [];
            let dataValues = [];
            for(const [key, value] of Object.entries(this.perfdata.data)){
                var date = new Date(parseInt(key, 10))
                datasetData.push({
                    x: parseInt(key, 10),
                    y: value
                });
                dataValues.push(value);
            }
            this.datasetData = datasetData;

            if(this.datasetData.length === 0){
                // Resolve TypeError: this.datasetData[0] is undefined
                // This will create an empty chart
                this.datasetData[0] = {};
            }

            /* let maxValue =   parseFloat(Math.max(...dataValues))
            let minValue =   parseFloat(Math.min(...dataValues))
            if(maxValue >= 10){
                this.options.scales.y.beginAtZero = true
            } else {
                this.options.scales.y.beginAtZero = false
            }
            if(maxValue > 90  && maxValue <= 100){
                this.options.scales.y.max = 120
            } else {
                this.options.scales.y.max = null
            } */

            let colors = []; //[{value:borderColor, ..}, {value: fillColor, ..}]
            let warn = '';
            let crit = '';
            let warnString = '';
            let critString = '';
            let displayString = '';
            if(
                this.perfdata.datasource.warn !== "" &&
                this.perfdata.datasource.crit !== "" &&
                this.perfdata.datasource.warn !== null &&
                this.perfdata.datasource.crit !== null
            ){
                warn = parseFloat(this.perfdata.datasource.warn);
                crit = parseFloat(this.perfdata.datasource.crit);
                warnString = 'warning-threshold: ' + warn;
                critString = 'critical-threshold: ' + crit;
                colors = this.getColors(warn, crit);
            }else{
                colors[0] = {[this.datasetData[0].x]: this.colors.defaultBorderColor};
                colors[1] = {[this.datasetData[0].x]: this.colors.defaultFillColor};
            }

            if(warnString !== '' && critString !== ''){
                displayString = warnString + ', ' + critString;
            }
            if(Chart.getChart("canvas")){
                Chart.getChart("canvas").destroy();
            }

            new Chart(this.$refs.canvas.getContext("2d"), {
                type: 'line',
                data: {
                    datasets: [
                        {
                            label: this.currentDataSource,
                            data: this.datasetData,
                            fill: true,
                            gradient: {
                                borderColor: {
                                    axis: 'x',
                                    colors: colors[0],
                                },
                                backgroundColor: {
                                    axis: 'x',
                                    colors: colors[1]
                                }
                            }
                        }
                    ]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    tooltips: {
                        mode: 'nearest'
                    },
                    plugins: {
                        title: {
                            display: true,
                            text: displayString
                        },
                        /* legend: {
                             position: 'top',
                             title: {
                                 display: true,
                                 text: this.currentGraphUnit || ''
                             }
                         }, */
                    },
                    elements: {
                        point: {
                            radius: 0,
                            hitRadius: 10
                        },
                    },
                    scales: {
                        x: {
                            adapters: {
                                date: {
                                    setZone: true,
                                    zone: this.timezone.user_timezone
                                }
                            },
                            type: 'time',
                            distribution: 'linear',
                            min: this.graphStart*1000,
                            max: this.graphEnd*1000,
                            time: {
                                unit: 'minutes',
                                displayFormats: {
                                    minutes: 'dd.LL T', //luxon-formats
                                    hour: 'dd T',
                                }
                            },
                            title: {
                                display: true,
                                text: 'Timeline'
                            },
                            ticks: {
                                maxTicksLimit: 10,
                                min: 5,
                                max: 10
                            },
                        },
                        y: {
                            max: this.options.scales.y.max,
                            type: 'linear',
                            beginAtZero: this.options.scales.y.beginAtZero,
                            title: {
                                position: 'left',
                                display: true,
                                text: this.currentGraphUnit || ''
                            },
                            ticks: {
                                minTicksLimit: 5,
                                maxTicksLimit: 10
                            }
                        }
                    },
                },
            });
        },

        getGraphData(host, service, merged){
            this.hostuuid = host;
            this.serviceuuid = service;
            this.mergedService = merged;
            this.setDataSources();
            this.getData();
        },

        setDataSources(){
            this.dataSources = [];
            for(var dsName in this.mergedService.Perfdata){
                this.dataSources.push(dsName);
            }
            if(this.mergedService.service_type === 32){
                this.dataSources = [this.mergedService.name];
            }
            if(this.dataSources.length > 0 && this.currentDataSource === ''){
                this.currentDataSource = this.dataSources[0];
            }
        },

        shiftTimestamps(performancedata){
            if(performancedata.length > 0){
                //Use the first metrics the server gave us.
                this.perfdata = {
                    datasource: performancedata[0].datasource,
                    data: {}
                };
                //Convert Servertime into user time
                for(var timestamp in performancedata[0].data){
                    //var frontEndTimestamp = (parseInt(timestamp, 10) + (this.timezone.user_time_to_server_offset * 1000));
                    var frontEndTimestamp = timestamp;
                    this.perfdata.data[frontEndTimestamp] = performancedata[0].data[timestamp];
                }
                this.currentGraphUnit = this.perfdata.datasource.unit;
            }else{
                this.perfdata = {
                    data: {},
                    datasource: {
                        ds: null,
                        name: null,
                        label: null,
                        unit: null,
                        act: null,
                        warn: null,
                        crit: null,
                        min: null,
                        max: null
                    }
                };
            }
        },

        getColors(warn, crit){
            let data = this.datasetData
            let fColor
            let bColor
            let fColors = {}
            let bColors = {}
            const fillColors = ["rgb(18, 132, 10, 0.2)", "rgb(255, 187, 51, 0.2)", "rgb(204, 0, 0, 0.2)"] //ok,warn, crit
            const borderColors = ["rgb(18, 132, 10, 1)", "rgb(255, 187, 51, 1)", "rgb(192, 0, 0, 1)"] //ok,warn, crit

            for(let i = 0; i < data.length; i++){
                if(warn > crit){
                    if(data[i].y >= warn){
                        fColor = fillColors[0];
                        bColor = borderColors[0];
                    }
                    if(data[i].y < warn && data[i].y >= crit){
                        fColor = fillColors[1];
                        bColor = borderColors[1];
                    }
                    if(data[i].y < crit){
                        //color = scaleStops[2]
                        fColor = fillColors[2];
                        bColor = borderColors[2];
                    }
                }else{
                    if(data[i].y < warn){
                        fColor = fillColors[0];
                        bColor = borderColors[0];
                    }
                    if(data[i].y >= warn && data[i].y < crit){
                        fColor = fillColors[1];
                        bColor = borderColors[1];
                    }
                    if(data[i].y >= crit){
                        fColor = fillColors[2];
                        bColor = borderColors[2];
                    }
                }
                let key = data[i].x;
                fColors[key] = fColor;
                bColors[key] = bColor;
            }
            return [bColors, fColors];
        },

        renderSelected(){
            this.getGraphData(this.hostuuid, this.serviceuuid, this.mergedService, this.timezone, this.state);
        },
    },

    created(){
    },

    mounted(){
    },

}
</script>

<style scoped>
</style>
