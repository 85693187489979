<template>
    <div class="mt-2" ref="loader">
        <b-container class="container-lg">
            <b-row class="p-0">
                <b-col class="text-left">
                    <h5><i class="fa-solid fa-gauge-high"></i> {{ $t('Dashboard') }}</h5>
                </b-col>
                <b-col class="text-right">
                    <button type="button" class="btn btn-light btn-sm" @click="refreshData">
                        <i class="fa-solid fa-arrows-rotate"
                           :class="{'fa-spin': loading}"></i>
                    </button>
                </b-col>
            </b-row>

            <div>
                <b-card-group deck class="mb-2 d-none d-lg-block">
                    <b-card :header="$t('user')" class="text-center font-weight-bold">
                        <b-card-text>
                            {{ $t('welcome') }} {{ this.$store.state.user }}
                        </b-card-text>
                    </b-card>
                </b-card-group>

                <b-card-group deck>
                    <b-card :header="$t('dashboard.hoststats')" class="">
                        <template #header>
                            <span class="font-weight-bold">
                                {{ $t('dashboard.hoststats') }}
                            </span>
                            <router-link :to="{name: 'hostsIndex', params: {}}"
                                         class="btn btn-primary float-right">
                                {{ $t('all') }}
                                <b-badge pill variant="light">{{ indexData.hosts.total }}</b-badge>
                            </router-link>
                        </template>
                        <b-card-text>
                            <div class="row">
                                <div class="col-12" v-on:click="getHostsIndexByState('up')" role="button">
                                    <div class="d-flex mt-2">
                                        {{ $t('up') }}
                                        <span class="d-inline-block ml-auto">{{
                                                indexData.hosts.up
                                            }} / {{ indexData.hosts.total }}</span>
                                    </div>
                                    <b-progress
                                        class="progress-sm mb-3 border-radius-10"
                                        :value="(indexData.hosts.up/indexData.hosts.total*100)"
                                        variant="up"></b-progress>
                                </div>

                                <div class="col-12" v-on:click="getHostsIndexByState('down')" role="button">
                                    <div class="d-flex mt-2">
                                        {{ $t('down') }}
                                        <span class="d-inline-block ml-auto">{{
                                                indexData.hosts.down
                                            }} / {{ indexData.hosts.total }}</span>
                                    </div>
                                    <b-progress
                                        class="progress-sm mb-3 border-radius-10"
                                        :value="(indexData.hosts.down/indexData.hosts.total*100)"
                                        variant="down"></b-progress>
                                </div>

                                <div class="col-12" v-on:click="getHostsIndexByState('unreachable')" role="button">
                                    <div class="d-flex mt-2">
                                        {{ $t('unreachable') }}
                                        <span class="d-inline-block ml-auto">{{
                                                indexData.hosts.unreachable
                                            }} / {{ indexData.hosts.total }}</span>
                                    </div>
                                    <b-progress
                                        class="progress-sm mb-3 border-radius-10"
                                        :value="(indexData.hosts.unreachable/indexData.hosts.total*100)"
                                        variant="unreachable"></b-progress>
                                </div>


                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <b-button variant="outline-danger w-100" @click="unhandledHosts">
                                        <i class="fa-solid fa-triangle-exclamation warning"></i>
                                        {{ $t('unhandled') }}
                                        <b-badge pill variant="danger">{{ indexData.unhandledHosts.total }}</b-badge>
                                    </b-button>
                                </div>
                            </div>

                        </b-card-text>
                    </b-card>

                    <b-card :header="$t('dashboard.servicestats')" class="">
                        <template #header>
                            <span class="font-weight-bold">
                                {{ $t('dashboard.servicestats') }}
                            </span>
                            <router-link :to="{name: 'servicesIndex', params: {}}"
                                         class="btn btn-primary float-right">
                                {{ $t('all') }}
                                <b-badge pill variant="light">{{ indexData.services.total }}</b-badge>
                            </router-link>
                        </template>

                        <b-card-text>
                            <div class="row">
                                <div class="col-12" v-on:click="getServicesIndexByState('ok')" role="button">
                                    <div class="d-flex mt-2">
                                        {{ $t('ok') }}
                                        <span class="d-inline-block ml-auto">{{
                                                indexData.services.ok
                                            }} / {{ indexData.services.total }}</span>
                                    </div>
                                    <b-progress
                                        class="progress-sm mb-3 border-radius-10"
                                        :value="(indexData.services.ok/indexData.services.total*100)"
                                        variant="ok"></b-progress>
                                </div>

                                <div class="col-12" v-on:click="getServicesIndexByState('warning')" role="button">
                                    <div class="d-flex mt-2">
                                        {{ $t('warning') }}
                                        <span class="d-inline-block ml-auto">{{
                                                indexData.services.warning
                                            }} / {{ indexData.services.total }}</span>
                                    </div>
                                    <b-progress
                                        class="progress-sm mb-3 border-radius-10"
                                        :value="(indexData.services.warning/indexData.services.total*100)"
                                        variant="warning"></b-progress>
                                </div>

                                <div class="col-12" v-on:click="getServicesIndexByState('critical')" role="button">
                                    <div class="d-flex mt-2">
                                        {{ $t('critical') }}
                                        <span class="d-inline-block ml-auto">{{
                                                indexData.services.critical
                                            }} / {{ indexData.services.total }}</span>
                                    </div>
                                    <b-progress
                                        class="progress-sm mb-3 border-radius-10"
                                        :value="(indexData.services.critical/indexData.services.total*100)"
                                        variant="critical"></b-progress>
                                </div>

                                <div class="col-12" v-on:click="getServicesIndexByState('unknown')" role="button">
                                    <div class="d-flex mt-2">
                                        {{ $t('unknown') }}
                                        <span class="d-inline-block ml-auto">{{
                                                indexData.services.unknown
                                            }} / {{ indexData.services.total }}</span>
                                    </div>
                                    <b-progress
                                        class="progress-sm mb-3 border-radius-10"
                                        :value="(indexData.services.unknown/indexData.services.total*100)"
                                        variant="unknown"></b-progress>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <b-button variant="outline-danger w-100" @click="unhandledServices">
                                        <i class="fa-solid fa-triangle-exclamation warning"></i>
                                        {{ $t('unhandled') }}
                                        <b-badge pill variant="danger">{{ indexData.unhandledServices.total }}</b-badge>
                                    </b-button>
                                </div>
                            </div>

                        </b-card-text>
                    </b-card>
                </b-card-group>
            </div>
        </b-container>
    </div>
</template>

<script>

import router from "../router";

export default {
    name: "DashboardsIndex",
    data(){
        return {
            loading: true,
            requestStatus: false,
            indexData: {
                hosts: {
                    total: 0,
                    up: 0,
                    down: 0,
                    unreachable: 0
                },
                services: {
                    total: 0,
                    ok: 0,
                    warning: 0,
                    critical: 0,
                    unknown: 0
                },
                unhandledHosts: {
                    total: 0
                },
                unhandledServices: {
                    total: 0
                }
            },
            hostsFilter: {
                filter: {
                    current_state: [],
                    problem_has_been_acknowledged: '',
                    scheduled_downtime_depth: ''
                }
            },
            servicesFilter: {
                filter: {
                    current_state: [],
                    problem_has_been_acknowledged: '',
                    scheduled_downtime_depth: ''
                }
            }
        };
    },
    methods: {
        getData(refresh = 0){
            let loader = this.$loading.show();
            this.loading = true;
            axios.post('/dashboards/index.json')
                .then(response => {
                        this.requestStatus = true;
                        this.indexData.hosts.total = response.data.statusData.hoststatusSum;
                        this.indexData.hosts.up = response.data.statusData.hoststatusCount[0];
                        this.indexData.hosts.down = response.data.statusData.hoststatusCount[1];
                        this.indexData.hosts.unreachable = response.data.statusData.hoststatusCount[2];
                        this.indexData.services.total = response.data.statusData.servicestatusSum;
                        this.indexData.services.ok = response.data.statusData.servicestatusCount[0];
                        this.indexData.services.warning = response.data.statusData.servicestatusCount[1];
                        this.indexData.services.critical = response.data.statusData.servicestatusCount[2];
                        this.indexData.services.unknown = response.data.statusData.servicestatusCount[3];

                        this.indexData.unhandledHosts.total = response.data.statusData.unhandledHostsSum;
                        this.indexData.unhandledServices.total = response.data.statusData.unhandledServicesSum;
                        if(refresh === 1){
                            this.$notify({
                                type: 'success',
                                title: this.$t('refresh'),
                            });
                        }
                })
                .catch(error => {
                    console.log(error)
                   /* if(error.response.status != 401) {
                        this.$notify({
                            type: 'error',
                            title: this.$t('error'),
                        });
                    } */
                })
                .finally(() => {
                    loader.hide();
                    this.loading = false;
                });
        },
        refreshData(){
            this.getData(1);
        },
        unhandledHosts(){
            this.hostsFilter.filter.current_state = [
                'down',
                'unreachable'
            ];
            this.hostsFilter.filter.problem_has_been_acknowledged = false;
            this.hostsFilter.filter.scheduled_downtime_depth = false;

            router.push({name: 'hostsIndex', params: {filter: this.hostsFilter}});
        },
        unhandledServices(){
            this.servicesFilter.filter.scheduled_downtime_depth = false
            this.servicesFilter.filter.problem_has_been_acknowledged = false
            this.servicesFilter.filter.current_state = [
                'warning',
                'critical',
                'unknown'
            ];
            router.push({name: 'servicesIndex', params: {filter: this.servicesFilter}});
        },
        getHostsIndexByState(currentState){
            this.hostsFilter.filter.current_state = [currentState];
            router.push({name: 'hostsIndex', params: {filter: this.hostsFilter}});
        },
        getServicesIndexByState(currentState){
            this.servicesFilter.filter.current_state = [currentState];
            router.push({name: 'servicesIndex', params: {filter: this.servicesFilter}});
        },
    },
    created(){
        this.getData();
    }
}
</script>

<style scoped>
</style>
