<template>
    <div>
        <reset-check-time :uuids="uuids" :servicestatus="servicestatus" @command="command">
        </reset-check-time>
        <maintenance :uuids="uuids" :downtime="downtime" :servicestatus="servicestatus" @command="command">
        </maintenance>
        <acknowledge v-if="servicestatus.currentState > 0" :uuids="uuids" :servicestatus="servicestatus" :acknowledgement="acknowledgement"
                     @command="command">
        </acknowledge>
        <passive-check :uuids="uuids" :servicestatus="servicestatus" @command="command">
        </passive-check>
        <flap :uuids="uuids" :servicestatus="servicestatus" @command="command">
        </flap>
        <notifications :uuids="uuids" :servicestatus="servicestatus" @command="command">
        </notifications>
        <custom-notification :uuids="uuids" :servicestatus="servicestatus" @command="command">
        </custom-notification>
    </div>
</template>

<script>
import Maintenance from "./ServiceMaintenance";
import ResetCheckTime from "./ServiceResetCheckTime";
import Acknowledge from "./ServiceAcknowledge";
import PassiveCheck from "./ServicePassiveCheck";
import Flap from "./ServiceFlap";
import Notifications from "./ServiceNotifications";
import CustomNotification from "./ServiceCustomNotification";

export default {
    name: "Actions",
    components: {Maintenance, ResetCheckTime, Acknowledge, PassiveCheck, Flap, Notifications, CustomNotification},
    props: [
        'uuids',
        'servicestatus',
        'downtime',
        'acknowledgement'
    ],
    methods: {
        refresh(){
            this.$emit('refresh-event');
        },
        command(payload){
            let loader = this.$loading.show();
            axios.post('/services/externalCommands.json', payload)
                .then(response => {
                    this.$notify({
                        type: 'success',
                        title: this.$t('Command successfully sent'),
                        text: this.$t('refresh'),
                        duration: 7000
                    });
                    setTimeout(() => {
                        this.$emit('refresh-event');
                    }, 7000);
            }).catch(error => {
                this.$notify({
                    type: 'error',
                    title: this.$t('error')
                });
            })
            .finally(() => {
                loader.hide();
            });
        },
    }
}
</script>

<style scoped>

</style>
