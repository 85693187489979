<template>
    <div>
        <b-button v-b-toggle.collapse-ack-status class="w-100 mb-1"
                  variant="outline-secondary"
                  v-show="!servicestatus.problemHasBeenAcknowledged"
                  ref="toggleAckSubmit">
            <i class="fa-solid fa-user"></i>
            {{ $t('action-items.acknowledge')}}
        </b-button>
        <b-collapse id="collapse-ack-status" class="mt-2 mb-2 w-100">
            <b-list-group-item>
                <b-form>
                    <b-form-group
                        id="input-group-ack-text"
                        :label="$t('forms.message')"
                        label-for="textarea-acknowlegde">
                        <b-form-textarea v-model="$v.acknowledgeForm.comment.$model"
                                         :class="{ 'is-invalid': $v.acknowledgeForm.$error }"
                                         id="textarea-acknowlegde"
                                         name="textarea-acknowlegde"
                                         rows="3"
                                         max-rows="6"
                                         placeholder="comment"
                        ></b-form-textarea>
                        <div class="invalid-feedback" v-if="!$v.acknowledgeForm.comment.required">{{ $t('Field is required') }}
                        </div>
                    </b-form-group>
                    <b-form-group id="input-group-ack-check"
                        :description="$t('forms.sticky-description', {obj: 'Service'})">
                        <b-form-checkbox
                            v-model="acknowledgeForm.sticky"
                            id="acknowlegde-sticky"
                            value=2
                            unchecked-value=0>
                            {{ $t('forms.sticky') }}
                        </b-form-checkbox>
                    </b-form-group>
                    <b-form-group id="input-group-ack-check2"
                                  :description="$t('forms.notify-description', {obj: 'Service'})">
                        <b-form-checkbox
                            v-model="acknowledgeForm.notify"
                            id="acknowlegde-notify"
                            value=1
                            unchecked-value=0>
                            {{ $t('forms.notify') }}
                        </b-form-checkbox>
                    </b-form-group>
                    <b-button variant="success" @click="submitAcknowledgement"
                              class="w-100">{{ $t('forms.send-command') }}
                    </b-button>
                </b-form>
            </b-list-group-item>
        </b-collapse>

        <b-button v-b-toggle.collapse-ack class="w-100 mb-1"
                  variant="outline-danger"
                  v-show="servicestatus.problemHasBeenAcknowledged"
                  ref="toggleAck">
            <i class="fa-solid fa-trash"></i>
            {{ $t('action-items.remove-acknowledge')}}
        </b-button>
        <b-collapse id="collapse-ack" class="mt-2 mb-2 w-100">
            <b-list-group-item>
                {{ $t('action-items.acknowledge-delete-confirm') }}
                <b-button variant="danger" @click="deleteAck" class="w-100">
                    {{ $t('forms.send-command') }}
                </b-button>
            </b-list-group-item>
        </b-collapse>
    </div>
</template>

<script>
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";

export default {
    name: "Acknowledge",
    mixins: [validationMixin],
    props: [
        'uuids',
        'servicestatus',
        'acknowledgement'
    ],
    data(){
        return {
            acknowledgeForm: {
                comment: '',
                sticky: '0',
                notify: '1'
            },
        }
    },
    validations: {
        acknowledgeForm: {
            comment: {
                required,
            }
        }
    },
    methods: {
        submitAcknowledgement(){
            this.$v.acknowledgeForm.$touch();
            if(this.$v.acknowledgeForm.$anyError){
                return;
            }
            let externalCommands = [];
            externalCommands.push({
                serviceUuid: this.uuids.serviceuuid,
                hostUuid: this.uuids.hostuuid,
                sticky: parseInt(this.acknowledgeForm.sticky, 10),
                notify: parseInt(this.acknowledgeForm.notify, 10),
                persistent: 1,
                author: this.$store.state.user,
                comment: this.acknowledgeForm.comment,
                command: 'ACKNOWLEDGE_SVC_PROBLEM'
            })
            this.$refs.toggleAckSubmit.click();
            this.$emit('command', externalCommands);
        },
        deleteAck(){
            let externalCommands = [];
            externalCommands.push({
                serviceUuid: this.uuids.serviceuuid,
                hostUuid: this.uuids.hostuuid,
                command: 'REMOVE_SVC_ACKNOWLEDGEMENT'
            })
            this.$refs.toggleAck.click();
            this.$emit('command', externalCommands);
        },
    }
}
</script>

<style scoped>

</style>
